
import produce from 'immer';

export default (state = { loading: false, type: null, person: [], tag: null, activities: [], userCanvasMapping: null, hasMore: false, pageNum: null, loadedTime: null, isOpen: false, zIndex: 1200 }, action) => produce(state, draftState => {
	const alignCommentActivity = activity =>{
		if(!activity.action.writerId) return activity;
		const content = activity.content[0];
		const commentId = activity.action._id
		const commonComment = content.comments.find(comment => comment._id === commentId);
		if(!commonComment) return activity;
	    const newComments = [
	    	commonComment,
          	...content.comments.filter(item => item !== 'role'),
        ];
        content.comments = newComments;
		return activity;
	}

	switch (action.type){
		case 'OPEN_DRAWER':
			draftState = { ...draftState, isOpen: true };
			break;
		case 'OPEN_DRAWER_$':{
      const { person, activities } = action.data;
			draftState = { ...draftState, person, activities, isOpen: true };
			break;
		}
		case 'SET_ACTIVITY':
			draftState.loading = true;
			draftState.person = [];
			draftState.activities = [];
			break;

		case 'SET_ACTIVITY_$':	{

      let hasMore;
      let { person, activities, type, userCanvasMapping } = action.json;

			if(!person){
				draftState.person = [];
				return;
			}

      if(activities.length === 0){
        hasMore = false;
      } else {
        hasMore = true;
      }
      /*
		  activities = activities.filter(function(item, index, self) {
	      return self.findIndex(a => item.content[0]._id == a.content[0]._id) == index;
		  })
		  */
		  //handling duplicate contents
		  /*
		  activities.forEach((activity, index, activities) => {
		  	//when activity is duplicate
		  	const firstIndex = activities.findIndex(a => activity.content[0]._id == a.content[0]._id);
			  if(firstIndex !== index){
			  	//when first activity is comment
			  	if(activities[firstIndex].writerIds){
				  	if(activity.type){
				  		activities[firstIndex].type = activity.type;
				  		activities.splice(index, 1);
				  	} else {
				  		activities.splice(index, 1);
				  	}
				  //whend first activity is reaction
			  	} else {
				  	if(activity.type){
				  		activities.splice(index, 1);
				  	} else {
				  		activity.type = activities[firstIndex].type;
				  		activities.splice(firstIndex, 1);
				  	}
			  	}
			  }	
		  })

      */
      activities = activities.map(activity => {
      	return alignCommentActivity(activity)
      })
			draftState = { ...draftState, loading: false, person, activities, type, userCanvasMapping, loadedTime: new Date().toUTCString(), hasMore, pageNum: 1, tag: null };
			break;
		}

		case 'MORE_ACTIVITY': {
			draftState.hasMore = false;
			break;
		}

		case 'MORE_ACTIVITY_$':{
      if(action.json.length === 0){
        draftState.hasMore = false;
        break;
      } else {
      	draftState.hasMore = true;
      }
      draftState.pageNum++;
      /*
      action.data.activities.forEach(activity => {
        const content = activity.content[0]
        if(activity.text) content.comments = content.comments.concat(content.comments);
      })
      */

      //filter to unique
      let { activities } = action.json;
      if(activities.length === 0){
        draftState.hasMore = false;
      } else {
        draftState.hasMore = true;
      }
      activities = activities.map(activity => {
      	return alignCommentActivity(activity)
      })
      draftState.activities = draftState.activities.concat(activities);
      
      /*
			activities.forEach((activity, index, activities) => {
		  	//when activity is duplicate
		  	const firstIndex = activities.findIndex(a => activity.content[0]._id == a.content[0]._id);
			  if(firstIndex !== index){
			  	//when first activity is comment
			  	if(activities[firstIndex].writerIds){
				  	if(activity.type){
				  		activities[firstIndex].type = activity.type;
				  		activities.splice(index, 1);
				  	} else {
				  		activities.splice(index, 1);
				  	}
				  //whend first activity is reaction
			  	} else {
				  	if(activity.type){
				  		activities.splice(index, 1);
				  	} else {
				  		activity.type = activities[firstIndex].type;
				  		activities.splice(firstIndex, 1);
				  	}
			  	}
			  }	
		  })
		  
      activities.map(activity => {
      	return alignCommentActivity(activity)
      })
      */
			break;
		}
      
		case 'ACTIVITY_BY_TAG': {
			draftState = { ...draftState, activities:[], loadedTime: new Date().toUTCString() };
			break;
    }
		case 'ACTIVITY_BY_TAG_$': {
			let { person, activities } = action.data;
		  activities = activities.filter(function(item, index, self){
	      return self.findIndex(a => item.content[0]._id == a.content[0]._id) == index;
		  })
      activities.map(activity => {
      	return alignCommentActivity(activity)
      })
      console.log('filtered activities', activities)
			draftState = { ...draftState, person, tag: action.tag, pageNum:1, activities, loadedTime: new Date().toUTCString() };
			break;
    }
		case 'CHANGE_USER_DESCRIPTION_$':
			draftState.person[0].description = action.text;
			break;

		case 'DELETE_USER_TAG_$':
      draftState.person[0].hashtags = draftState.person[0].hashtags.filter(hashtag => hashtag.name !== action.hashtag.name);
			break;

		case 'ADD_USER_TAG_$$':
      draftState.person[0].hashtags = draftState.person[0].hashtags.concat([action.hashtag]);
			break;

		case 'CLOSE_DRAWER_$':
			draftState = { ...draftState, isOpen: false };
			break;

		case 'FOLLOW_PERSON':
      draftState.person[0].followStatus = [{status: 'FOLLOW'}]; 
			//draftState = { ...draftState, person, isOpen: true };
			break;

		case 'UNFOLLOW_PERSON':
      draftState.person[0].followStatus = [];
			//draftState = { ...draftState, person, isOpen: true };
			break;

	}
	return draftState;
})

