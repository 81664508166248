import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
import Skeleton from '@mui/material/Skeleton';

import CloseIcon from '@mui/icons-material/Close';


import { Avatar, Chip, InputBase, Button, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Typography, Divider, CircularProgress, IconButton } from '@mui/material';

export default (props) => {
	const root = useSelector(state => state.api).root;
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [ input, setInput ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const { updateContent, setIsEditFormClicked } = props;
  const [ comment, setComment ] = useState(props.comment);
  //const { text, hashtags, persontags } = comment;
  const commentId = comment._id;

	const handleEditFormCancle = () => {
		setIsEditFormClicked(false);
	};

	const handleEditSubmit = () => {
    if(input === '')return;
		setLoading(true);
		fetch(`${root}/api/comment/${comment._id}`, { 
			method: 'put', 
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ text: input }), 
			credentials: 'include', 
		})
			.then(res => res.json())
			.then(json => {
				setLoading(false);
				console.log('comment submit success', json)
				updateContent(draft => {
					const index = draft.comments.findIndex(function(element){return element._id === commentId});
          draft.comments[index].text = input;
				})
				handleEditFormCancle();
			})
			.catch(err => console.log(err))
	};

  /*
  const pullHashtag = hashtag => {
    setHashtags(hashtags.filter(el => el.name !== hashtag.name));
  }
  const pullPersontag = persontag => {
    setPersontags(persontags.filter(el => el.username !== persontag.username ));
    setInput(input.replace(activeWord, ''));
  }
  */
  const handleKeyPress = e => {
    const value = e.target.value;
    const key = e.key;
    const caretIndex = e.target.selectionStart;
    const keyCode = e.keyCode;
    console.log('key is', key);
    console.log('keyCode is', keyCode);

    if(!e.target.value) return;
    
    //non editing cases
    //esc

    if(key === 'Enter'){
      console.log('Enter, handleEditSubmit')
      return handleEditSubmit();
    }
  }    


  return (
    <div>
      { 
        loading
        ?
          <div style={{
            display:'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div style={{marginLeft: '7px'}}>
              <Skeleton animation="wave" variant="circular" width={40} height={40} />
            </div>
          </div>
        :
          <div style={{
            display:'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            opacity: 1}}
          >
            <div>
              <IconButton>
                <Avatar alt={user.username} src={user.profileImageUrl}/>
              </IconButton>
            </div>
            <div style={{
              width: '100%',
              justifyContent: 'flex-start',
            }}>
              <div align='left'>
                {comment.hashtags.map(hashtag => <Chip label={'#' + hashtag.name} />)}
                {comment.persontags.map(persontag => <Chip label={'@' + persontag.username} />)}
              </div>
              <InputBase
                defaultValue={comment.text}
                inputProps={{style: {fontSize: 14}}}
                InputLabelProps={{style: {fontSize: 14}}}
                multiline
                onChange={e => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                onKeyDown={ev => {
                /*
                if(ev.key === 'Enter'){
                handleEditSubmit();
                }
                */
                if(ev.keyCode === 27){
                  handleEditFormCancle()
                }
                }}
              />
            </div>
          <IconButton style={{paddingLeft: '5px'}} size="small" onClick={handleEditFormCancle} color='secondary'><CloseIcon size='small'/></IconButton>
        </div>
        }
    </div>
  );
}
				


