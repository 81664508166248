import { combineReducers } from 'redux'
import user from './user';
import newsfeed from './newsfeed';
import search from './search';
import suggest from './suggest';
import dialogs from './dialogs';
import api from './api';
import hot from './hot';
import dialog_c from './dialog_c';
import activity from './activity';

const rootReducer = combineReducers(
	{ hot, search, suggest, newsfeed, user, api, dialogs, dialog_c, activity }
)

// This would produce the following state object
export default rootReducer;
