import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';

export default function CommentOwnerMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
	const root = useSelector(state => state.api).root;
	const { comment, updateContent, setIsEditFormClicked } = props; 
	const dispatch = useDispatch();

	let commentId = comment._id;



  const handleCommentDisclosure = () => {
    setLoading(true);
    fetch(`${root}/api/comment/disclosure?commentId=${commentId}`, { method: 'put', credentials: 'include'  })
      .then(res => {
        setLoading(false);
        console.log('comment disclosure success');
        updateContent(draft => {
          const index = draft.comments.findIndex(function(element)
            {
              return element._id === commentId
            }
          );
          draft.comments[index].isPublic = !draft.comments[index].isPublic;
        })
        handleClose();
      })
      .catch(err => console.log(err))
  }

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
    props.setMouseOver(false);
  }

	const handleEditFormClick = () => {
		setIsEditFormClicked(true);
	}

	const handleCommentDelete = () => {
    setLoading(true);
		fetch(`${root}/api/comment/${commentId}`, { method: 'delete', credentials: 'include'  })
			.then(res => {
        if(res.updated = true){
        }
        setLoading(false);
				console.log('comment delete success');
				updateContent(draft => {
					draft.comments = draft.comments.filter(comment => comment._id !== commentId);
					draft.commentCount--;
				});
				handleClose();
			})
			.catch(err => console.log(err))
	}
	
  return (
    <div>
			<IconButton 
        size='small' 
        edge="end" 
        aria-controls='simple-menu' 
        aria-label="aria-haspopup" 
        onClick={handleMenuClick} 
      >
				<MoreVertIcon fontSize='small'/>
			</IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {//<MenuItem onClick={handleCommentDisclosure}>{comment.isPublic? 'Switch to private' : 'Switch to public'}</MenuItem>
        }
        <MenuItem onClick={handleEditFormClick}>Edit</MenuItem>
				<MenuItem onClick={handleCommentDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
