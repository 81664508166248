import { InputBase, Button, Grid, Avatar, ListItem, ListItemAvatar, CircularProgress, Chip, IconButton } from '@mui/material'
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
import Skeleton from '@mui/material/Skeleton';

import CloseIcon from '@mui/icons-material/Close';

export default props => {
	const root = useSelector(state => state.api).root;
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [ loading, setLoading ] = useState(false);
	const { reply, updateReply, setIsEditFormClicked } = props;
	const [ input, setInput ] = useState(reply.text);
	const [ persontags, setPersontags ] = useState(reply.persontags);

	const handleEditSubmit = () => {
    if(input === '') return;
		setLoading(true);
		fetch(`${root}/api/reply/${reply._id}`, { 
			method: 'put', 
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ text: input }), 
			credentials: 'include' 
		})
			.then(res => {
				setLoading(false);
				console.log('replyEdit success', res)
				updateReply(draft => {
					draft.text = input; 
				});
				handleEditFormCancle();
			})
			.catch(err => console.log(err))
	};

	const handleEditFormCancle = () => {
		setIsEditFormClicked(false);
	};

  const handleKeyPress = e => {
    const value = e.target.value;
    const key = e.key;
    const caretIndex = e.target.selectionStart;
    const keyCode = e.keyCode;
    console.log('key is', key);
    console.log('keyCode is', keyCode);

    if(!e.target.value) return;

    if(key === 'Enter'){
      console.log('Enter, handleReplySubmit')
      return handleEditSubmit();
    }
  }

  return (
    <div>
        {
          loading
          ?
      <div style={{
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{marginLeft: '7px', marginTop: '5px'}}>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        </div>
      </div>
    :
      <div style = {{
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div>
          <IconButton>
            <Avatar src={user.profileImageUrl}/>
          </IconButton>
        </div>
          <div style ={{
            width: '100%',
            justifyContent: 'flex-start',
          }}>
          <div align='left'>
            {reply.persontags.map(persontag => <Chip label={'@'+persontag.username} />)}
          </div>
          <InputBase
            defaultValue={reply.text}
            multiline
            inputProps={{style: {fontSize: 14}}}
            InputLabelProps={{style: {fontSize: 14}}}
            onChange={e => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            onKeyDown={ev => {
              /*
              if(ev.key === 'Enter'){
                handleEditSubmit();
              }
              */
            if(ev.keyCode === 27){
              handleEditFormCancle()
            }}}
          />
            {/*
                <div className={classes.buttons}>
                    <Button className={classes.button} size="small" onClick={handleEditSubmit} color='secondary'>edit</Button>
                    <Button className={classes.button} size="small" onClick={handleEditFormCancle} color='secondary'>cancle</Button>
                </div>
            */
            }
            </div>
            <IconButton style={{paddingLeft: '5px'}} size="small" onClick={handleEditFormCancle} color='secondary'><CloseIcon size='small'/></IconButton>
          </div>
        }
    </div>
  );
}
				


