import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector, useDispatch } from 'react-redux';

const ReportMenu = props => {
	//props: onDelete / onEdit
	const [ anchorEl, setAnchorEl ] = useState(null);
	const user = useSelector(state => state.user);
	const root = useSelector(state => state.api).root;
  const { commentId, replyId, updateContent } = props;

  const handleMenuClick = event => {
    if(user) return;
    setAnchorEl(event.currentTarget);
  }
  const handleClose = (props) => {
		setAnchorEl(null);
    props.setMouseOver(false);
  };

	const report = () => {
		fetch(`${root}/api/report/reply/${props.replyId}/1`, { method: 'get', credentials: 'include'  })
			.then(res => {
        console.log(res);
			})
			.catch(err => console.log(err))
    updateContent(draft => {
      const a = draft.comments.find(element => element._id === commentId);
      a.replies = a.replies.filter(reply => reply._id !== replyId);
      a.replyCount--;
    })
    handleClose();
    //activate report alert
    props.handleOpen();
	};

	return (
        <div>
      <IconButton
          edge="end"
          aria-controls='simple-menu'
          aria-label="aria-haspopup"
          onClick={handleMenuClick}
          size="large">
				<MoreVertIcon/>
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={report}>Report</MenuItem>
				<MenuItem onClick={handleClose}>Cancle</MenuItem>
			</Menu>
		</div>
    );
}

export default ReportMenu;
