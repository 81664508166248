/* global chrome */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import {Paper, Typography, Button, CircularProgress, CardActionArea } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { red, grey } from '@mui/material/colors';

import SimpleContent from 'components/simpleContent'
import Skeleton from '@mui/material/Skeleton';

import { useSelector, useDispatch } from 'react-redux';

import Masonry from "@mui/lab/Masonry"



const PREFIX = 'Search';

const classes = {
    simpleContent: `${PREFIX}-simpleContent`,
    toggleButton: `${PREFIX}-toggleButton`,
    topSection: `${PREFIX}-topSection`,
    results: `${PREFIX}-results`,
    entityname: `${PREFIX}-entityname`,
    progress: `${PREFIX}-progress`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    /*
	container: {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
	},
	*/
	marginBottom: '100px',
    [`& .${classes.simpleContent}`]: {
    [theme.breakpoints.down('md')]: {
      width: '400px'
    },
  },

    [`& .${classes.toggleButton}`]: {
    marginLeft: '50px',
	},


    [`& .${classes.results}`]: {
		paddingTop: '100px'
	},

    [`& .${classes.entityname}`]: {
		margin: theme.spacing(2),
	},

    [`& .${classes.progress}`]: {
		left: '50%',
	}
}));

const Search = props => {

	const { currentText, contents, hasMore, loading } = useSelector(state => state.search); 
	const { root, extensionInstalled, extensionId } = useSelector(state => state.api);

  const user = useSelector(state => state.user);
	
	const dispatch = useDispatch();


	const userTags = user? user.newsfeed.tags : []; 
	const isAdded = user&&userTags.filter(userTag=> userTag.name === currentText).length > 0;
  
	console.log('isAdded is', isAdded)
	console.log('searched contents are', contents)

	let searchedContents = contents.map(content =>{ 

		return (
            <Root>
				<Paper>
					<SimpleContent content={content} />
				</Paper>
			</Root>
        );
		}
	)

	const handleLoadMore = () => {
		dispatch({ type: 'MORE_SEARCH', method: 'get', path: `/api/search/more?name=${encodeURIComponent(currentText.name)}` })
	}
	
	const handleAddEntity = name => {
		dispatch({ type: 'ADD_NEWSFEED_TAG', method: 'post', path: `/api/newsfeed/tag/?name=${encodeURIComponent(name)}` })
	}

	const handleDeleteEntity = name => {
		dispatch({ type: 'DELETE_NEWSFEED_TAG', method: 'delete', path: `/api/newsfeed/tag/?name=${encodeURIComponent(name)}`, tag: { name }})
	}

	return(
		<div style={{textAlign: 'center'}}>
			{user
			?
				<div style={{marginTop:'100px'}}>
					<Typography className={classes.entityName}>{currentText}</Typography>
					{
						/*
							<ToggleButton
					            className={classes.toggleButton}
								value="check"
								selected={isAdded}
								onChange={() => {
					            if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
									!isAdded? handleAddEntity(currentText) : handleDeleteEntity(currentText)
								}}
							>
								{ !isAdded ? 'Add' : 'Added' }
							</ToggleButton>
						*/
					}
				</div>
			:
				null
			}
			{
				contents.length > 0
				?
					<div className={classes.results}>
						dataLength={contents.length}
						next={handleLoadMore}
						hasMore={hasMore}
					>
		              <div style={{margin:'10px'}}>
		                <Masonry columns={{ms_1:1, ms_2:2, ms_3:3, ms_4:4, ms_5:5}} spacing={2}>
		                	{searchedContents}
		                </Masonry>
		              </div>
					</div>
				:
				loading?
					<div style={{margin:'10px'}}>
						<Skeleton width={300} height={500}/>
					</div>
				:
          <div style={{marginTop: '100px'}}>
					  <Typography>No discussion is found yet</Typography>	
          </div>
		}
	</div>
	)
}

export default Search;
