import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useImmer } from "use-immer";

import { useNavigate } from 'react-router-dom';

import { Stage, Layer, Rect, Group, Line, Transformer, Text, Image } from 'react-konva';
import { Paper, Button, IconButton, Popover, Divider, TextField, MenuList, MenuItem, ListItem, ListItemText, ListItemIcon, Typography, FormGroup, FormControlLabel, Skeleton, Box, Autocomplete, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Html, Portal } from 'react-konva-utils';

import ContentCut from '@mui/icons-material/ContentCut';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Switch from '@mui/material/Switch';
import Cloud from '@mui/icons-material/Cloud';

import SimpleCanvas from 'components/simpleCanvas'
import Canvas from 'components/canvas';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

import Textbox from './textbox';

const Premium = () => {
  const [scale, setScale] = useState(1);
  const [moveScale, setMoveScale] = useState(1);

  const [canvasMappings, updateCanvasMappings] = useImmer([]);
  const [currentCanvasMappings, updateCurrentCanvasMappings] = useImmer([]);
  const [input, setInput] = useState('');

  const [isOptionOpen, setIsOptionOpen] = useState(false);

  const scaleRef = useRef(1); // Initialize scale as a ref
  const wheelTimerRef = useRef(null);

  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [selectedObject, setSelectedObject] = useState(null);
  
  const stageRef = useRef();
  const [draggingRect, setDraggingRect] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  const [isPanEnabled, setIsPanEnabled] = useState(true);

  const [texts, updateTexts] = useImmer([]);
  const [lines, updateLines] = useImmer([]);

  const [selectedLine, setSelectedLine] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const [anchorPosition, setAnchorPosition] = useState({x: 0, y: 0});
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const [isObjectContextMenuOpen, setIsObjectContextMenuOpen] = useState(false)
  const [isCanvasMappingContextMenuOpen, setIsCanvasMappingContextMenuOpen] = useState(false);

  const [hoveredCanvasId, setHoveredCanvasId] = useState(null);

  const [isTextboxOpen, setIsTextboxOpen] = useState(false)

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  console.log('scale', scale)
  console.log('stagePosition', stagePosition)


  //let activity = useSelector(state => state.activity);
  const { root } = useSelector(state => state.api);
  const user = useSelector(state => state.user);
  const params = useParams();

  const handleMappingSave = () => {
    fetch(`${root}/auth/premium/save`, 
      { 
        method: 'post', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ scale, stagePosition, texts, currentCanvasMappings }), 
        credentials: 'include' 
      }
    )
      .then(res => res.json())
  };

  const handleOptionClick = (e, canvasMapping) => {
    setIsOptionOpen(false);
    console.log('handleOptionClick', e, canvasMapping)
    fetch(`${root}/api/canvas/load?person_name=${user.username}&canvas_name=${canvasMapping.name}`, 
      { 
        method: 'get', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include' 
      }
    )
      .then(res => res.json())
      .then(json => {
        console.log('json:', json)
        updateCurrentCanvasMappings([...currentCanvasMappings, {...canvasMapping, x: 0, y: 0, type: 'CANVAS', canvas: [json]}])
      })
  };


  const handleObjectDragEnd = (e, o) => {
    console.log('handleobjectdragend', e, o)
    switch (o.type) {
      case 'TEXT':
        updateTexts(draft => {
          const foundIndex = draft.findIndex(d => d.id === o.id);
          if (foundIndex !== -1) {
            // Modify the object at the found index
            draft[foundIndex].x = e.target.x();
            draft[foundIndex].y = e.target.y();
          }
          return draft;
        });
        break;

      case 'CANVAS':
        updateCurrentCanvasMappings(draft => {
          const foundIndex = draft.findIndex(d => d.name === o.name);
          if (foundIndex !== -1) {
            // Modify the object at the found index
            draft[foundIndex].x = e.target.x();
            draft[foundIndex].y = e.target.y();
          }
          return draft;
        });
        break; 
      default:
    }
  };

  const handleStageClick = (e) => {
    setIsContextMenuOpen(false);
    setIsObjectContextMenuOpen(false);

    const stage = e.target.getStage();
    const stageScale = stage.scaleX();
    const stagePosition = stage.position();
    const pointerPosition = stage.getPointerPosition();
    
    // Calculate the position relative to the stage (considering scale and position)
    const relativePosition = {
      x: (pointerPosition.x - stagePosition.x) / stageScale,
      y: (pointerPosition.y - stagePosition.y) / stageScale
    };
    
    console.log("Clicked Position on Stage:", relativePosition);
    closeAllContextMenu();
  };

  const calculatePosition = e => {
    //const stage = e.target.getStage();
    const stage = stageRef.current;
    const stageScale = stage.scaleX();
    const stagePosition = stage.position();
    const pointerPosition = stage.getPointerPosition();
    
    // Calculate the position relative to the stage (considering scale and position)
    const relativePosition = {
      x: (pointerPosition.x - stagePosition.x) / stageScale,
      y: (pointerPosition.y - stagePosition.y) / stageScale
    };
    return relativePosition;
  }

  const handleStageDragEnd = () => {
    // Get the current position of the stage
    const stage = stageRef.current;
    const stagePos = stage.position();
    
    // Update the state with the new position
    setStagePosition({ x: stagePos.x, y: stagePos.y });
  };


  let wheelTimer;

const handleWheel = e => {
    e.preventDefault();

    const scaleBy = 1.05;
    const stage = stageRef.current;
    const oldScale = scale;
    const pointerX = e.clientX;
    const pointerY = e.clientY;

    // Calculate the position of the stage relative to the viewport
    const stageBox = stage.container().getBoundingClientRect();
    const offsetX = pointerX - stageBox.left;
    const offsetY = pointerY - stageBox.top;


  const isShiftPressed = e.shiftKey;

  if (isShiftPressed) {
    // If Shift key is pressed, pan the stage
    const deltaX = e.deltaX;
    const deltaY = e.deltaY;

    setStagePosition(prevPosition => ({
      x: prevPosition.x - deltaX,
      y: prevPosition.y - deltaY,
    }));

    return; // Exit the function early when panning
  }

    // Calculate the position of the mouse pointer relative to the stage
    const mousePointTo = {
      x: (offsetX - stagePosition.x) / oldScale,
      y: (offsetY - stagePosition.y) / oldScale,
    };

    // Apply scaling
    const newScale = e.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if(newScale > 5||newScale < 0.1) return;
    setScale(newScale);

    // Calculate the new position of the stage based on the mouse pointer
    setStagePosition({
      x: offsetX - mousePointTo.x * newScale,
      y: offsetY - mousePointTo.y * newScale,
    });

    // Clear previous timer
    clearTimeout(wheelTimer);

    // Clear previous timer
    clearTimeout(wheelTimerRef.current);

    // Set new timer to detect when wheel event is finished
    wheelTimerRef.current = setTimeout(() => {
      // Additional actions to be performed after wheel event is finished
      console.log('setting move scale');
      setMoveScale(scale)
    }, 200); // Adjust the timeout value as needed
  }

  const handleContextMenu = e => {
    console.log('context menu is', e)
    e.evt.preventDefault();

    if(e.target.constructor.name !== e.currentTarget.constructor.name) return;

    const { x, y } = calculatePosition(e);
    setAnchorPosition({x, y})
    setIsContextMenuOpen(true)
  }

  const handleCanvasMappingContextMenu = (e, canvasMapping) => {
    console.log('handleCanvasMappingContextMenu', e, canvasMapping)
    closeAllContextMenu();
    setIsContextMenuOpen(false);

    e.preventDefault();
    const { x, y } = calculatePosition(e);

    setSelectedObject(canvasMapping)
    setAnchorPosition({x, y})
    setIsObjectContextMenuOpen(true)
  }

  const closeAllContextMenu = () => {
    setIsContextMenuOpen(false);
    setIsObjectContextMenuOpen(false);
  }

  const handleObjectContextMenu = (e, object) => {
    console.log('object context menu', e)
    //closeAllContextMenu();
    e.evt.preventDefault();
    //e.evt.stopPropagation();

    const { x, y } = calculatePosition(e);

    setSelectedObject(object);
    setAnchorPosition({x, y});

    switch (object.type) {
      case 'CANVAS':
        setIsCanvasMappingContextMenuOpen(true);
        break;

      case 'TEXT':
        setIsObjectContextMenuOpen(true);
        break;
      default:
    }
  }

  const handleTextboxOpen = () => {
    setIsContextMenuOpen(false);
    setIsTextboxOpen(true);
  }


  const handleDeleteObject = () => {
    setIsContextMenuOpen(false);
    setIsObjectContextMenuOpen(false);

    switch (selectedObject.type) {
      case 'CANVAS':
        const newCurrentCanvasMappings = currentCanvasMappings.filter(c => c.name !== selectedObject.name);
        updateCurrentCanvasMappings(draft => newCurrentCanvasMappings);
        break;

      case 'TEXT':
        const newTexts = texts.filter(t => t.id !== selectedObject.id);
        updateTexts(draft => {
          draft = newTexts 
          return draft;
        });
        break;
      default:
    }
  }

  const handleDeleteCanvasMapping = canvasMapping => {
      const okay = window.confirm(
        'Are you sure to delet this canvas?'
      )
      if(okay){
        fetch(`${root}/api/canvas?canvasName=${canvasMapping.name}`, 
          { 
            method: 'delete', 
            credentials: 'include' 
          }
        )
          .then(res => res.json())
          .then(json => {
            console.log('json is', json)
            const filteredCanvasMappings = canvasMappings.filter(c => c._id !== canvasMapping._id)
            updateCanvasMappings(draft => filteredCanvasMappings);
          })
      }



  }

  const EllipsisText = props => {
    const { children } = props

    return (
      <div style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 100,
      }}>
        {children}
      </div>
    )
  }

  const renderedCanvasMappings = useMemo(() => {
    return currentCanvasMappings.map((c, index, arr) => {
      return (
        <Group
          key={c._id}
          id={index}
          x={c.x} 
          y={c.y}
          draggable
          onDragStart={(e) => {
            console.log('drag started')
          }}
          onDragEnd={e => handleObjectDragEnd(e, c)}
        >
          <Rect x={0} y={-40} fill='#ececec' width={100} height={40} opacity={hoveredCanvasId == c._id? 1 : 0} onMouseOver={e => setHoveredCanvasId(c._id)} onMouseOut={e => setHoveredCanvasId(null)}/>
          <Html>
            <div>
              <div align='center'>
                <Chip
                  color='primary'
                  style={{
                    backgroundColor: c.color,
                    marginBottom: 60*moveScale,
                    transform: `scale(${1/moveScale})`
                  }}
                  label={<EllipsisText>{'#' + c.name}</EllipsisText>}
                  onClick={() => navigate(`/${user.username}/${c.name}`)}
                  onContextMenu={e => handleCanvasMappingContextMenu(e, c)}
                />
              </div>
              <Paper elevation={10} style={{height: 'auto', resize: 'horizontal', overflow: 'auto', borderRadius: '50px'}} onContextMenu={e => handleCanvasMappingContextMenu(e, c)} onMouseOver={e => setHoveredCanvasId(c._id)} onMouseOut={e => setHoveredCanvasId(null)}>
                {
                  //<SimpleCanvas canvasMapping={c}/>
                }
                <Canvas canvasData={c.canvas[0]}/>
              </Paper>
            </div>
          </Html>
        </Group>
    )});
  }, [currentCanvasMappings, moveScale]); 

  useEffect(() => {
    fetch(`${root}/auth/premium/load`, 
      { 
        method: 'get', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include' 
      }
    )
      .then(res => res.json())
      .then(json => {
        console.log('premium load json is', json)
        setLoading(false);
        const userCanvasMapping = json;

        setScale(userCanvasMapping.scale);
        setMoveScale(userCanvasMapping.scale);
        setStagePosition(userCanvasMapping.stagePosition);
        updateCanvasMappings(draft => userCanvasMapping.canvasMappings);
        updateCurrentCanvasMappings(draft => userCanvasMapping.currentCanvasMappings);
        updateTexts(draft => userCanvasMapping.texts);
      })
  }, [])

  if(loading){
    return(
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center'}}>
        <Skeleton width={400} height={600}/>
      </div>
    )
  }

  console.log('scale is', scale);
  console.log('stagePosition is', stagePosition);

  return (
    <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100px',
        marginTop: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2147480000,
        pointerEvents: 'none',
        /* Optionally, you can add more styling here */
      }}>
        <Autocomplete
          style={{pointerEvents: 'auto'}}
          id="country-select-demo"
          sx={{ width: 200 }}
          options={canvasMappings}
          getOptionLabel={(option) => option.name}
          open={isOptionOpen}
          onOpen={() => setIsOptionOpen(true)}
          onClose={() => setIsOptionOpen(false)}
          renderOption={(props, option) => (
            <>

              <ListItem
                key={option.name}
                sx={{ cursor: 'pointer' }}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCanvasMapping(option)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <Chip
                  color='primary'
                  style={{
                    backgroundColor: option.color,
                  }}
                  label={<EllipsisText>{'#' + option.name}</EllipsisText>}
                  onClick={e => handleOptionClick(e, option)}
                />
              </ListItem>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search your canvas"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Button style={{marginLeft: '10px', pointerEvents: 'auto'}} variant='outlined' onClick={handleMappingSave}>
          save
        </Button>
      </div>
        <div onWheel={handleWheel} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          <Stage
            ref={stageRef}
            width={window.innerWidth}
            height={window.innerHeight}
            scaleX={scale}
            scaleY={scale}
            x={stagePosition.x}
            y={stagePosition.y}
            onClick={handleStageClick}
            draggable={isPanEnabled} // Disable stage dragging when dragging rectangle
            onDragEnd={handleStageDragEnd}
            onContextMenu={handleContextMenu}
          >
            <Layer>
              {renderedCanvasMappings}
              {selectedShape && (
              <Transformer
                anchorSize={8}
                borderDash={[6, 2]}
                borderEnabled
                rotateEnabled={true}
                keepRatio={false}
                resizeEnabled={true}
                ref={(node) => {
                  if (node && selectedShape) {
                    node.nodes([selectedShape]);
                  }
                }}
              />
              )}
              {
                texts.map(t => 
                  <Text
                    key={t.id}
                    text={t.text}
                    x={t.x}
                    y={t.y}
                    fontSize={t.fontSize}
                    fontFamily={t.fontFamily}
                    fill={t.fill}
                    draggable
                    onDragEnd={e => handleObjectDragEnd(e, t)}
                    onContextMenu={e => handleObjectContextMenu(e, t)}
                  />
                )
              }
              {isContextMenuOpen&&(
                <Group x={anchorPosition.x} y={anchorPosition.y}>
                  <Html>
                    <div style={{transform: `scale(${1/scale})`, transformOrigin: 'top left', width: '300px'}}>
                      <Paper>
                        <MenuList>
                          <MenuItem onClick={handleTextboxOpen}>
                            <ListItemIcon>
                              <FormatColorTextIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Add texts</ListItemText>
                          </MenuItem>
                          <Divider />
                          <MenuItem>
                            <ListItemIcon>
                              <Cloud fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Drawing features will be added soon!</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </div>
                  </Html>
                </Group>
              )}
              {isObjectContextMenuOpen&&(
                <Group x={anchorPosition.x} y={anchorPosition.y}>
                  <Html>
                    <div style={{transform: `scale(${1/scale})`, transformOrigin: 'top left', width: '300px'}}>
                      <Paper>
                        <MenuList>
                          <MenuItem onClick={handleDeleteObject}>
                            <ListItemIcon>
                              <DeleteForeverIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </div>
                  </Html>
                </Group>
              )}
              {isCanvasMappingContextMenuOpen&&(
                <Group x={anchorPosition.x} y={anchorPosition.y}>
                  <Html>
                    <div style={{transform: `scale(${1/scale})`, transformOrigin: 'top left', width: '300px'}}>
                      <Paper>
                        <MenuList>
                          <MenuItem onClick={handleDeleteObject}>
                            <ListItemIcon>
                              <DeleteForeverIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </div>
                  </Html>
                </Group>
              )}
              {
                isTextboxOpen
                ?
                  <Textbox x={anchorPosition.x} y={anchorPosition.y} scale={scale} setIsTextboxOpen={setIsTextboxOpen} texts={texts} updateTexts={updateTexts}/>
                :
                  null  
              }       
              </Layer>
          </Stage>
        </div>
    </>
  );
};

export default Premium;