import produce from 'immer';

export default (state = { loading: false, contents: [], hasMore: false, pageNum: 0, loadedTime: null }, action) => produce(state, draftState => {
	switch (action.type){
		case 'SET_SUGGEST':
			draftState.contents = [];
			draftState.hasMore = false;
			draftState.loading = true;
			break;

		case 'SET_SUGGEST_$':
			const loading = false;
      let hasMore;
      if(action.json.length == 0){
        hasMore = false;
      } else {
        hasMore = true;
      }
			const { contents }  = action.json;
			console.log('contents in reducer', contents.length)
			const loadedTime = new Date().toUTCString();
			draftState = { ...draftState, loading, hasMore, contents, pageNum: 1, loadedTime: new Date().toUTCString() };
			break;

		case 'MORE_SUGGEST_$':
			if(action.json.contents.length > 0){
				draftState.contents = draftState.contents.concat(action.json.contents);
				draftState.pageNum++;
			} else {
				draftState.hasMore = false;
			}
			break;
	}
	return draftState;
})

