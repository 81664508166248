import produce from 'immer';

const reducer = (state = { signIn: { open: false }, extension: { open: false }}, action) => produce(state, draftState => {
	switch (action.type){
		case 'OPEN_SIGN_$':
		    draftState.signIn.open = true;
			break;
		case 'CLOSE_SIGN_$':
		    draftState.signIn.open = false;
			break;

		case 'OPEN_EXTENSION_REQUEST_$':
			draftState.extension.open = true;
			break;

		case 'CLOSE_EXTENSION_REQUEST_$':
			draftState.extension.open = false;
			break;
	}
	return draftState;
})

export default reducer;

