/* global chrome */
import React, { Component, useState, useEffect } from 'react';
import CommentEditForm from './forms/commentEditForm';
import ReplyForm from './forms/replyForm';
import ReplyOwnerMenu from './menus/replyOwnerMenu';

import { Avatar, Button, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Typography, Divider, Chip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector, useDispatch } from 'react-redux'

import ReplyReportMenu from './menus/replyReportMenu';
import ReplyEditForm from './forms/replyEditForm';
import produce from 'immer';
import { useImmer } from 'use-immer';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';


const Reply = props => {
	const { updateContent, updateComment, contentId, commentId } = props;
	
	const [ isEditFormClicked, setIsEditFormClicked ] = useState(false);
	const [ isReplyFormClicked, setIsReplyFormClicked ] = useState(false);
	const [ isReplyDeleted, setIsReplyDeleted ] = useState(false);
	const [ mouseOver, setMouseOver ] = useState(false);
	const user = useSelector(state => state.user);
	const { root, CLIENT_ROOT, appType } = useSelector(state => state.api);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [ reply, updateReply ] = useImmer(props.reply);

	useEffect(() => {
		updateReply(draft => props.reply);
  }, [props.reply]);

	const isReplyOwner = user && reply.writer? reply.writer[0]._id === user._id : false;

	const handleMouseMove = () => {
    if(mouseOver) return;
    setMouseOver(true);
	}

	const handleHashtagClick = hashtag => {
    if(appType == 'WEB'){
        navigate(`/${reply.writer[0].username}/${hashtag.name}`)
    } else {
        //extension;
        window.open(CLIENT_ROOT + `/${reply.writer[0].username}/${hashtag.name}`, '_blank');
    }
	}


	const handleReplyLike = () => {
    if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
    //if(isReplyOwner) return;
		if(reply.userReaction.length === 0){
			fetch(`${root}/api/reaction/reply/${reply._id}/1/0`, { method: 'put', credentials: 'include' })
				.then(res => console.log('reaction update success'))
				.catch(err => console.log(err))
			updateContent(draft => {
					const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
				a.likeCount++; 
				a.userReaction = [{ type: 1 }];
			});
		} else {
		switch (reply.userReaction[0].type){ 
			case -1:
				fetch(`${root}/api/reaction/reply/${reply._id}/1/-1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
					updateContent(draft => {
						const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
						a.likeCount++; 
						a.dislikeCount--;
						a.userReaction[0].type = 1;
					});
				break;
			case 0:
				fetch(`${root}/api/reaction/reply/${reply._id}/1/0`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => {
						const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
					a.likeCount++; 
					a.userReaction[0].type = 1;
				});
				break;
			case 1:
				fetch(`${root}/api/reaction/reply/${reply._id}/1/1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => {
						const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
					a.likeCount--; 
					a.userReaction[0].type = 0;
				});
				break;
		}
	}}

	const handleReplyDislike = () => {
    if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
    //if(isReplyOwner) return;
		if(reply.userReaction.length === 0){
			fetch(`${root}/api/reaction/reply/${reply._id}/-1/0}`, { method: 'put', credentials: 'include' })
				.then(res => console.log('reaction update success'))
				.catch(err => console.log(err))
			updateContent(draft => {
					const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
				a.dislikeCount++; 
				a.userReaction = [{type: -1}];
			});
		} else {
		switch (reply.userReaction[0].type){ 
			case -1:
				fetch(`${root}/api/reaction/reply/${reply._id}/-1/-1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => {
						const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
					a.dislikeCount--;
					a.userReaction[0].type = 0;
				});
				break;
			case 0:
				fetch(`${root}/api/reaction/reply/${reply._id}/-1/0`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => {
						const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
					a.dislikeCount++; 
					a.userReaction[0].type = -1;
				});
				break;
			case 1:
				fetch(`${root}/api/reaction/reply/${reply._id}/-1/1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => {
						const a = draft.comments.find(element => element._id === commentId).replies.find(element => element._id === props.reply._id);
					a.likeCount--; 
					a.dislikeCount++; 
					a.userReaction[0].type = -1;
				});
				break;
		}
	}}
  const handleReplyFormClick = () => {
    if(!user)return dispatch({ type: 'OPEN_SIGN_$' });
    setIsReplyFormClicked(prev => !prev)
  }

	return (
        <div>
					{
						!isEditFormClicked 
						?
							<div>
                <div style={{
							    display: 'flex',
							    justifyContent: 'flex-start',
							    paddingBottom: '10px',
							  }} onMouseOver={()=>setMouseOver(true)} onMouseOut={()=>setMouseOver(false)} onMouseMove={handleMouseMove}>
                  <div onClick={()=>dispatch({type: 'SET_ACTIVITY', personName: reply.writer[0].username})}>
                  	<IconButton
                      onClick={()=>{
                      	/*
                        chrome.runtime.sendMessage({type: "OPEN_ACTIVITY", personName: reply.writer[0].username }, function(response) {
                        })
                        */
                        //chrome.runtime.sendMessage({type: 'UPDATE_ACTIVITY_WINDOW', url: CLIENT_ROOT + `/${reply.writer[0].username}`, windowId: 'socialbrowse-activity'})
                        window.open(CLIENT_ROOT + `/${reply.writer[0].username}`, '_blank');
                      }}
                  	>
	                    <Avatar alt="R" src={reply.writer[0].profileImageUrl}/>
                    </IconButton>
                  </div>
                  <div style={{
										flexGrow: 1,
								    display: 'flex',
								    justifyContent: 'flex-start',
								    flexDirection: 'column',
									}}>
                    <div style={{display:'flex', flexDirection: 'row'}}>
                      <Typography>{reply.writer[0].username || reply.writer[0].email + "  " + reply.createdAt.substr(0, 10)}</Typography>
                      <Typography style={{paddingLeft: '15px'}} >{ moment(reply.createdAt).fromNow() }</Typography>
                    </div>
                      {/* reply.targetOwner? <Button style={{marginRight: '20px'}}>{reply.targetOwner.username}</Button> : null*/ }
                      <Typography>
	                      <span align='left' style={{marginRight: reply.persontags.length>0||reply.hashtags.length>0? '10px': '0px'}}>
      	                  {reply.hashtags.map(hashtag => {
	                           let chipColor;
	                           const common = user.hashtags.filter(el => el.name.toLowerCase() === hashtag.name.toLowerCase())
	                           if(common.length > 0) chipColor = common[0].color;
	                           return (<Chip style={{margin: '5px'}} onClick={e => handleHashtagClick(hashtag)} label={'#' + hashtag.name} color={chipColor? 'primary' : undefined} style={{backgroundColor: chipColor}} />)
	                        })}
	                        {	reply.persontags&&reply.persontags.map(persontag => <Chip style={{margin: '5px'}} label={'@'+persontag.username} />)} 
	                      </span>
                        	{ reply.replyId? '@' + reply.targetOwner.username + "\xa0\xa0" + reply.text : reply.text }
                      </Typography>
                    <div style={{
									    display: 'flex',
									    justifyContent: 'flex-start',
									    alignItems: 'center',
										}}>
                      {reply.likeCount<=0? 0 : reply.likeCount}
                      <IconButton
                        IconStyle={{width: 60, height: 60}}
                        aria-label="thumb-up"
                        onClick={handleReplyLike}
                        size="small"
                      >
                        {
                          reply.userReaction.length > 0 && reply.userReaction[0].type === 1 
                          ? 
                            <FavoriteIcon fontSize='small' style={{color: 'red'}}/>
                          :
                            <FavoriteBorderIcon fontSize='small'/>
                        }
                      </IconButton>
                      <Button onClick={handleReplyFormClick} size='small' color='secondary'>
                        Reply      
                      </Button>
                      { 
		                    isReplyOwner 
		                    ? 
		                      <ReplyOwnerMenu contentId={contentId} commentId={commentId} replyId={reply._id} updateContent={updateContent} updateComment={updateComment} setIsEditFormClicked={setIsEditFormClicked} setMouseOver={setMouseOver}/> 
		                    : 
		                    	null
		                  }
                    </div>

                  </div>
                  {
                 /*
	                <div className={classes.menuSection} style={{opacity: mouseOver? 1 : 0}}>
	                  { 
	                    isReplyOwner 
	                    ? 
	                      <ReplyOwnerMenu contentId={contentId} commentId={commentId} replyId={reply._id} updateContent={updateContent} updateComment={updateComment} setIsEditFormClicked={setIsEditFormClicked} setMouseOver={setMouseOver}/> 
	                    : 
	                      <ReplyReportMenu commentId={commentId} replyId={reply._id} updateContent={updateContent} handleOpen={props.handleOpen} setMouseOver={setMouseOver}/> 
	                  }
	                </div>
	              */
	           	  }
              </div>
							{ isReplyFormClicked ? <ReplyForm reply={reply} updateContent={updateContent} setIsReplyFormClicked={setIsReplyFormClicked} setIsReplyOpen={props.setIsReplyOpen} /> : null }
							</div>
						:
							<ReplyEditForm reply={reply} updateReply={updateReply} setIsEditFormClicked={setIsEditFormClicked}/>
					}
				</div>
    );
}

export default Reply;

