import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, IconButton, Typography, Box, Paper, TextField, Button, CircularProgress, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ExtensionButton from 'components/extensionButton';


const PREFIX = 'index';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
      padding: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'white'
    }
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const rootStyle = {
  padding: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: 'white',
};

export default props => {

  const theme = useTheme();
	const { root, CLIENT_ROOT } = useSelector(state => state.api);

	const user = useSelector(state => state.user);
	const { extensionId, extensionInstalled, userLoaded } = useSelector(state => state.api);

  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [chosenColor, setChosenColor] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [description, setDescription] = React.useState(null);
  const [accountName, setAccountName] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const navigate = useNavigate();

  const [message, setMessage] = React.useState(null);

	const dispatch = useDispatch();

	const handleSwitchChange = () => {
		dispatch({type: "CHANGE_USER_DISCLOSURE"})
	}

	const switchCommentSetting = () => {
		dispatch({type: 'CHANGE_COMMENT_DISCLOSURE'})
	}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

	
	const	onChangeHandler = event => {
		console.log(event.target.files[0])
		//setSelectedFile(event.target.files[0])
		handleFileSubmit(event.target.files[0])
	}

	const handleFileSubmit = selectedFile => {
    setLoading(true);
		const data = new FormData(); 
		console.log('selectedFile is', selectedFile)
		data.append('image', selectedFile, selectedFile.name);
		fetch(`${root}/auth/picture`, { 
			method: 'post', 
			body: data, 
			credentials: 'include', 
		})
			.then(res => res.json())
			.then(json => {
        setLoading(false);
				console.log('json is', json)
        dispatch({type: 'CHANGE_USER_PICTURE_$', imgUrl: json.imgUrl })
				//dispatch({type: 'SET_USER', path: '/auth/user', method: 'get'})
			})
			.catch(err => console.log(err))
	}

	const handleNameSubmit = () => {
    if(!accountName) return;
    //const re = /^[a-zA-Z0-9_][a-zA-Z0-9_.]*/g
		const re =/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/g    

    if(!re.test(accountName)){
      setMessage('NOT_MATCH');
      return;
    }
    setMessage(null);
    setLoading(true);
		fetch(`${root}/auth/name`, { 
			method: 'post', 
			body: JSON.stringify({ text: accountName }), 
			credentials: 'include', 
      headers: { 'Content-Type': 'application/json' },
		})
			.then(res => res.json())
			.then(json => {
        setLoading(false);
        setMessage(json.type);
				console.log('json is', json)
				if(json.type === 'DONE'){
					console.log('done');
					dispatch({type: 'CHANGE_USER_NAME_$', text: accountName.toLowerCase() })
		    	//window.open(CLIENT_ROOT + '/extension/welcome', '_blank');
		    	navigate(`/extension/welcome`)
		    	//window.open(CLIENT_ROOT + '/extension/welcome', '_self');
        }
			})
			.catch(err => console.log(err))
	}

	useEffect(()=>{
    return;
		if(user&&user.completed){
    	//props.navigate(`/extension/welcome`)
    	window.open(CLIENT_ROOT + '/extension/welcome', '_self');
		}
	}, [user])

	if(!user) return null;

  return (
      <Root style={rootStyle}>
          <h1 className='h1'>Please choose your storage domain</h1>
          <div style={{paddingTop: '40px', alignSelf: 'center',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant='h4'>{CLIENT_ROOT}/</Typography>
            <TextField
              InputLabelProps={{
              style: { color: 'grey' },
              }}
              multiline
              placeholder="name"
              defaultValue={null}
              onChange={e=>setAccountName(e.target.value)}
            />
            <Button variant='outlined' style={{marginLeft: '45px'}}onClick={handleNameSubmit}>Choose</Button>
          </div>
          {
            user.completed
            ?
              null
            :
            	null
          }
          {
            message === 'NOT_MATCH'
            ?
              <Typography>Sorry, you cannot use this user name. For now, Platfor follows the Instagram user name policy.</Typography>
            :
            message === 'ALREADY_EXSIST' 
            ?
              <Typography>This user name already exists</Typography>
            :
            message === 'NAME_DONE' 
            ?
              <Typography>Done!</Typography>
            :
              null
          }
      </Root>
  );
}