import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';

import { Avatar, Input, Button, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Typography, Divider, CircularProgress, Paper, Popper, Chip, List, ListItemIcon, InputBase } from '@mui/material';


export default function (props) {
	const {root, appType} = useSelector(state => state.api);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [ loading, setLoading ] = useState(false);
  const [ optionLoading, setOptionLoading ] = useState(false);
	const {	comment, reply, updateContent, setIsReplyFormClicked } = props;
  const commentId = comment? comment._id : reply.commentId;
  
  const [ options, setOptions ] = useState([]);

	const [ input, setInput ] = useState('');
  const [ hashtags, setHashtags ] = useState([]);
  const [ persontags, setPersontags ] = useState([]);
  const [activeWord, setActiveWord ] = useState(null);
	const [ cursor, setCursor ] = useState(-1);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const inputRef = useRef(null);
  // Gets the word that the user's caret is positioned on.
  const getActiveWord = (value, caretIndex) => {
      const hashtagOrWordRegex = /#[a-z0-9_]+/ 
      let startingIndex;
      let endingIndex;
      let index = caretIndex-1;
      do {
        let cha = value[index];
        if(cha === ' ' || cha === '\n' || index === -1){
          startingIndex = index + 1;
          break;
        }
        index -= 1
      } while (index >= -1)

      let index2 = caretIndex;
      do {
        let cha = value[index2];
        if(cha === ' ' || cha === '\n' || index2 === value.length ){
          endingIndex = index2;
          break;
        }
        index2 += 1
      } while (index2 <= value.length)

      /*
      console.log('------');
      console.log(startingIndex);
      console.log(endingIndex);
      */
      return value.substring(startingIndex, endingIndex);
  }

   const openOptions = activeWord => {
    console.log('openOptions functioning');
    let options = [];
    if(activeWord[0] === '#'){
      let condition;
      try {
        condition = new RegExp(activeWord.substring(1), 'g')
      } catch {
        return closeOptions();
      }
      options = user.hashtags.filter(function (hashtag) {
        return condition.test(hashtag.name);
      });
      console.log('options', options)
      if(options.length>0){
        setOptions(options);
      } else {
        const hashtag = { name: activeWord.substring(1), loweredName: activeWord.substring(1).toLowerCase() }
        setOptions([hashtag])
      }
      setAnchorEl(inputRef.current);
      setCursor(0);
    } else if(activeWord[0] === '@'){
      setAnchorEl(inputRef.current);
      setCursor(0);
      const condition = new RegExp(activeWord.substring(1), 'g')
      /*
      const filtered = user.followings.filter(function (following) {
        condition.test(following.person[0].username);
      });
      filtered.forEach(following => {
        options.push(following.person[0])
      })
      setOptions(options);
      */
    
      setOptionLoading(true);
      fetch(`${root}/api/tag/user?text=${activeWord.substring(1)}`, 
        { 
          method: 'get', 
          credentials: 'include' 
        }
      )
        .then(res => res.json())
        .then(json => {
          console.log('json is', json)
          setOptionLoading(false);
          setOptions(options.concat(json.users));
          if(options.length == 0&&json.users.length == 0){
            closeOptions();
          }
          console.log('options are', options)
        })
    }
  }

  const closeOptions = () => {
    setActiveWord(null);
    setAnchorEl(null);
    setCursor(-1);
  }

  const addHashtag = (hashtag, activeWord) => {
    setHashtags([...hashtags, hashtag]);
    console.log('input in addHashtag', input);
    console.log(input.length);
    console.log('activeWord in addHashtag', activeWord);
    setInput('');
  }
  const addPersontag = (persontag, activeWord) => {
    console.log('input in addPersontag', input, activeWord);
    setPersontags([...persontags, persontag]);
    setInput(input.replace(activeWord, ''));
  }
  const pullHashtag = hashtag => {
    setHashtags(hashtags.filter(el => el.name !== hashtag.name));
    setInput(input.replace(activeWord, ''));
  }
  const pullPersontag = persontag => {
    setPersontags(persontags.filter(el => el.username !== persontag.username ));
    setInput(input.replace(activeWord, ''));
  }

  const handleOptionClick = option => {
    if(option.name){addHashtag(option, activeWord)}
    else addPersontag(option.person[0])
    closeOptions();
  }
  const handleTagDelete = tag => {
    if(tag.name) pullHashtag(tag)
    else pullPersontag(tag)
  }
  
  const handleKeyDown = e => {
    const value = e.target.value;
    const key = e.key;
    const caretIndex = e.target.selectionStart;
    const keyCode = e.keyCode;
    console.log('key is', key);
    console.log('keyCode is', keyCode);

    //non editing cases
    //esc
		if(e.keyCode === 27){
      e.preventDefault();
      closeOptions();
      return;
		}
    // arrow up/down button should select next/previous list element
    if (e.key === 'ArrowUp' && cursor > -1) {
      console.log('moving cursor')
      setCursor(cursor-1);
      e.preventDefault();
      return;
    } else if (e.key === 'ArrowDown' && -1 < cursor ) {
      if(cursor >= options.length-1) return;
      console.log('moving cursor')
      setCursor(cursor+1);
      e.preventDefault();
      return;
    }
  }
  const handleKeyPress = e => {
    const value = e.target.value;
    const key = e.key;
    const caretIndex = e.target.selectionStart;
    const keyCode = e.keyCode;
    console.log('key is', key);
    console.log('keyCode is', keyCode);

    if(key === 'Enter'&&cursor < 0){
      console.log('Enter, handleReplySubmit')
      return handleReplySubmit();
    }

    if(!e.target.value) return;
    //non editing cases
    //esc

    if(key === 'Enter' || e.code == 'Space'){
      if(options.length == 0) return;
      console.log('Enter or Space', cursor)
      closeOptions();
      e.preventDefault();
      if(options[cursor].name){
        addHashtag(options[cursor], activeWord); 
      } else {
        addPersontag(options[cursor], activeWord);
      }
      return;
    }

 
    if(['ArrowLeft',
        'ArrowRight',
        'ArrowDown',
        'ArrowUp',
        'Control',
        'Shift',
    ].includes(key)){
      closeOptions();
      return;
    }

    /*
    if(['Backspace',
        'Delete',
    ].includes(key)){
      const activeWord = getActiveWord(value, caretIndex);
        var re = /@[^\s!@#$%^&*()=+.\/,\[{\]};:'"?><]+/g
        if(activeWord&&activeWord.match(re)){
          pullHashtag({name: activeWord}, activeWord)
          e.preventDefault();
          closeOptions();
          return;
        }
    }
    */

    if(['Enter'].includes(key)){
      const activeWord = getActiveWord(value, caretIndex);
      console.log('Space && Enter is functioning', activeWord);
      const re = /#[^\s!@#$%^&*()=+.\/,\[{\]};:'"?><]+/g
      if(re.test(activeWord)){
        console.log('activeWord is a hashtag');
        e.preventDefault();
        addHashtag({name: activeWord}, activeWord)
        return;
      }
    } 
    closeOptions();
  }
  const handleKeyUp = e => {
    let value = e.target.value;
    const key = e.key;
    const caretIndex = e.target.selectionStart;
    if([' ',
    ].includes(e.key)){
      console.log('working!!', value.length);
      value = value.substring(0, value.length - 1);
      const activeWord = getActiveWord(value, caretIndex);
      console.log('act', activeWord);
      const re = /^#[^ !@#$%^&*(),.?":{}|<>]*$/g
      if(re.test(activeWord)){
        console.log('it passed hashtag test');
        e.preventDefault();
        addHashtag({name: activeWord.substring(1)}, activeWord)
        return;
      }
    } 
  }
  
  const open = anchorEl? true: false;

  const handleOnChange = e => {
    const value = e.target.value;

    console.log('handleOnChange functioning', value);
    const caretIndex = e.target.selectionStart;

    setInput(value)

    const activeWord = getActiveWord(value, caretIndex);
    console.log('activeWord in handleOnChange is', activeWord);
    
    setActiveWord(activeWord);
    
    if(activeWord.match(/#[^\s!@#$%^&*()=+.\/,\[{\]};:'"?><]+/g) || activeWord.match(/@[^\s!@#$%^&*()=+.\/,\[{\]};:'"?><]+/g) || activeWord === '#' || activeWord === '@'){
      return openOptions(activeWord);
    }
    closeOptions();
  }

  const resetInputs = () => {
    setInput('');
    setHashtags([]);
    setPersontags([]);
  }

  const dummyReply = {
    //commentId
    //contentId
    //createdAt
    //updatedAt
    //targetOwner
    //text
    //writer = [];
    //writerId

    _id: '1234',
    dislikeCount: 0,
    likeCount: 0,
    userReaction: [],
    persontags: [],
    hashtags:[],
  }


	const handleReplySubmit = () => {
    if(!user) return;
    if(input === ''&&persontags===[]) return;
		setLoading(true);
    setInput('');
    setPersontags([]);
    const dummy = {
      commentId,
      //contentId:
      createdAt: new Date().getTime().toString(),

      text: input,
      writer: [user],
      writerId: user._id,

      hashtags,
      persontags,

      ...dummyReply
    };

    updateContent(draft => {
      const a = draft.comments.find(element => element._id === commentId);
      if(a.replies){ 
        a.replies = [ ...a.replies, dummy ];
      } else {
        a.replies = [ dummy ];
      }
    });
    handleReplyFormCancle();

    const url = `${root}/api/reply?comment_id=${commentId}`
		fetch(url, 
			{ method: 'post', 
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ text: input, persontags, hashtags }), 
				credentials: 'include' 
			}
		)
			.then(res => res.json())
			.then(json => {
				setLoading(false);
				console.log('reply submit success', json)
				props.setIsReplyOpen(true);
				updateContent(draft => {
					const a = draft.comments.find(element => element._id === commentId);
					//a.replies = a.replies.concat(res);
          if(a.replies){ 
					  a.replies = [ ...a.replies.slice(0, -1), json.reply];
          } else {
            a.replies = [ json.reply ];
          }
					a.replyCount++;
				});
				//handleReplyFormCancle();
			})
			.catch(err => console.log(err))
	};

	const handleReplyFormCancle = () => {
		setIsReplyFormClicked(false);
	};

  return (
    <div>
  { 
    loading
    ? 
      <div style={{
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
        <div style={{margintTop: '5px', marginLeft: '7px'}}>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
        </div>
      </div>
    :
    <div style={{
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
      <div>
        <IconButton>
          <Avatar src={user.profileImageUrl}/>
        </IconButton>
      </div>
      <div style={{
        padding: '5px',
        width: '100%',
      }}>
        <div align='left'>
          {hashtags.map(hashtag => <Chip label={'#' + hashtag.name} onDelete={()=>pullHashtag(hashtag)} />)}
          {persontags.map(persontag => <Chip label={'@'+ persontag.username} onDelete={()=>pullPersontag(persontag)}/>)}
        </div>
        <InputBase
          autoFocus
          value={input}
          ref={inputRef}
          placeholder=""
          defaultValue=""
          multiline
          inputProps={{style: {fontSize: 14}}}
          InputLabelProps={{style: {fontSize: 14}}}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onKeyPress={handleKeyPress}
        />
        {
        /*
          <div className={classes.buttons}>
            <Button className={classes.button} size="small" onClick={handleReplySubmit} color='secondary'>Reply</Button>
            <Button className={classes.button} size="small" onClick={handleReplyFormCancle} color='secondary'>Cancle</Button>
          </div>
        */
        }
    </div>
      <IconButton style={{paddingLeft: '5px'}} size="small" onClick={handleReplyFormCancle} color='secondary'><CloseIcon size='small'/></IconButton>
      <Popper 
        style={{zIndex: 1500}}
        open={open} 
        anchorEl={anchorEl}
      >
        <Paper>
          <List component="nav" aria-label="main mailbox folders">
            {
                      options.map((option, i) => 
                        option.name
                        ?
                          <ListItem 
                            key={i}
                            style={{backgroundColor: cursor === i ? 'WhiteSmoke' : null}}
                            button onClick={()=>handleOptionClick(option, activeWord)}
                          >
                            <ListItemText 
                              primary={'#' + option.name}  
                            />
                          </ListItem>
                        :

                          <ListItem 
                            key={i}
                            style={{backgroundColor: cursor === i ? 'WhiteSmoke' : null}}
                            button onClick={()=>handleOptionClick(option, activeWord)}
                          >
                            <ListItemIcon>
                              <Avatar src={option.person[0].profileImageUrl}/>
                            </ListItemIcon>
                            <ListItemText
                              primary={'@' + option.person[0].username}
                            />
                          </ListItem>
                                        )
             }
                        </List>
                    </Paper>
        </Popper>
    </div>
  }
    </div>
  );
}
				


