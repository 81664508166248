import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, IconButton, AppBar, Tabs, Tab, Typography, Box, Paper, TextField, Button, CircularProgress, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";


import ExtensionButton from 'components/extensionButton';


const PREFIX = 'index';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
      padding: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'white'
    }
}));




function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(props) {

  const theme = useTheme();
	const { root } = useSelector(state => state.api);
	const user = useSelector(state => state.user);
	const { extensionId, extensionInstalled, userLoaded } = useSelector(state => state.api);

  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [chosenColor, setChosenColor] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [description, setDescription] = React.useState(null);
  const [accountName, setAccountName] = React.useState(null);

  const [message, setMessage] = React.useState(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSwitchChange = () => {
		dispatch({type: "CHANGE_USER_DISCLOSURE"})
	}

	const switchCommentSetting = () => {
		dispatch({type: 'CHANGE_COMMENT_DISCLOSURE'})
	}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

	const handleChangePassword = () => {
		setLoading(true);
		fetch(`${root}/auth/changepassword`, { 
			method: 'post', 
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ username: email, password, newPassword }), 
			credentials: 'include', 
		})
			.then(res => res.json())
			.then(json => {
				setLoading(false);
				console.log('json is', json)
				if(json.message === 'incorrect_password') return setMessage(json.message);
				if(json.message === 'password_changed') return setMessage('password is changed');
			})
			.catch(err => console.log(err))
  }
	
	const	onChangeHandler = event => {
		console.log(event.target.files[0]);
		handleFileSubmit(event.target.files[0]);
	}

	const handleNameSubmit = () => {
    if(!accountName) return;
    //const re = /^[a-zA-Z0-9_][a-zA-Z0-9_.]*/g
		const re =/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/g    

    if(!re.test(accountName)){
      setMessage('NOT_MATCH');
      return;
    }
    setMessage(null);
    setLoading(true);
		fetch(`${root}/auth/name`, { 
			method: 'post', 
			body: JSON.stringify({ text: accountName }), 
			credentials: 'include', 
      headers: { 'Content-Type': 'application/json' },
		})
			.then(res => res.json())
			.then(json => {
        setLoading(false);
        setMessage(json.type);
				console.log('json is', json)
				if(json.type === 'DONE'){
					console.log('done');
					dispatch({type: 'CHANGE_USER_NAME_$', text: accountName.toLowerCase() })
        	navigate(`/${accountName.toLowerCase()}`)
        }
			})
			.catch(err => console.log(err))
	}

	const handleColorSubmit = () => {
		console.log('chosenColor is', chosenColor)
		if(!chosenColor) return;
    setLoading(true);
		fetch(`${root}/auth/color`, { 
			method: 'post', 
			body: JSON.stringify({ color: chosenColor}), 
			credentials: 'include', 
      headers: { 'Content-Type': 'application/json' },
		})
			.then(res => res.json())
			.then(json => {
        setLoading(false);
        setMessage(json.type);
				console.log('json is', json)
				const { hex, rgb } = chosenColor;
				dispatch({type: 'CHANGE_USER_COLOR_$', color: { hex, rgb } })
    
			})
			.catch(err => console.log(err))
	}

	const handleDescriptionSubmit = () => {
    if(!description) return;
    setMessage(null);
    setLoading(true);
		fetch(`${root}/auth/description`, { 
			method: 'post', 
			body: JSON.stringify({ text: description }), 
			credentials: 'include', 
      headers: { 'Content-Type': 'application/json' },
		})
			.then(res => res.json())
			.then(json => {
				console.log('json is', json)
        setMessage(json.type);
        setLoading(false)
				if(json.type==='DONE') dispatch({type: 'CHANGE_USER_DESCRIPTION_$', text: description })
			})
			.catch(err => console.log(err))
	}

	const handleFileSubmit = selectedFile => {
    setLoading(true);
		const data = new FormData(); 
		console.log('selectedFile is', selectedFile)
		data.append('image', selectedFile, selectedFile.name);
		fetch(`${root}/auth/picture`, { 
			method: 'post', 
			body: data, 
			credentials: 'include', 
		})
			.then(res => res.json())
			.then(json => {
        setLoading(false);
				console.log('json is', json)
        dispatch({type: 'CHANGE_USER_PICTURE_$', imgUrl: json.imgUrl })
				//dispatch({type: 'SET_USER', path: '/auth/user', method: 'get'})
			})
			.catch(err => console.log(err))
	}

	const handleDeleteAccount = () => {
		fetch(`${root}/auth/user`, { 
			method: 'delete', 
			credentials: 'include', 
		})
			.then(res => res.json())
			.then(json => {
				dispatch({type: 'DESTROY_USER_$'});
			})
			.catch(err => console.log(err))
	}

  function handleSignOut(event) {
    navigate('/en');
		fetch(root + `/auth/signout`, { credentials: 'include' })
			.then(res => {
				return res.json();
			})
			.then(json => {
				console.log('json is', json)
				dispatch({type: 'DESTROY_USER_$'});
			})
			.catch(err => console.log(err))
  }


  if(!user) return null;

	const views = 
		value === 0
		? 
			<Paper className={classes.root}>
    /*
				<TextField
					label="e-mail"
					className={classes.textField}
					id="name"
					type="username"
					autocomplete="username"
					margin="normal"
					variant="outlined"
					onChange={e=>setEmail(e.target.value)}
				/>
				<TextField
					id="outlined-password-input"
					label="password"
					className={classes.textField}
					type="password"
					autoComplete="password"
					margin="normal"
					variant="outlined"
					onChange={e=>setPassword(e.target.value)}
				/>
				<TextField
					id="outlined-password-input"
					label="new password"
					className={classes.textField}
					type="password"
					margin="normal"
					variant="outlined"
					onChange={e=>setNewPassword(e.target.value)}
				/>
				<Button onClick={handleChangePassword}>change password</Button>
				{
					loading
					?
						<CircularProgress color='secondary'/>
					:
						message
				}
    */
			</Paper>
		:
			<Paper>
				<input type="file" name='image' onChange={onChangeHandler} />
				<Button onClick={handleFileSubmit}>Upload</Button>
			</Paper>
		;

  return (
      <Root className={classes.root}>
      {/*
              <FormGroup>
               {
               // <FormControlLabel control={<Switch />} onChange={handleSwitchChange} checked={user.isPublic? false: true} label={user.isPublic? "Bookmark: public": "Bookmark: private"} />
             	 }
                <FormControlLabel control={<Switch />} onChange={switchCommentSetting} checked={user.isCommentPublic? false: true} label={user.isCommentPublic? "Default comment: public": "Default comment: private"} />
                {
                	user.isCommentPublic
                	?	
                		<Typography color='primary'>Anyone can view your words and the webpage is suggested to people</Typography>
                	:
                		<Typography color="primary">Only your followers can view your words.</Typography>
                }
              </FormGroup>
            */}
          <div style={{paddingTop: '40px', alignSelf: 'center',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={()=>{
                    if(!user.completed) return;
                    props.navigate(`/${user.username}`)
                }}
                size="large">
              <Avatar src={user.profileImageUrl}/>
            </IconButton>
            <input style={{paddingLeft: '20px'}} type="file" name='image' onChange={onChangeHandler} /> 
          </div>
          <div style={{paddingTop: '40px', alignSelf: 'center',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <TextField
              InputLabelProps={{
                          style: { color: 'grey' },
                        }}
             	label="username"
              //variant="outlined"
              multiline
              defaultValue={user.completed? user.username : null}
              onChange={e=>setAccountName(e.target.value)}
            />
            <Button style={{marginLeft: '45px'}}onClick={handleNameSubmit}>Edit</Button>
          </div>
          {
            user.completed
            ?
              null
            :
              <Typography color="primary">Please choose your social @name</Typography>
          }
          {
            message === 'NOT_MATCH'
            ?
              <Typography>Sorry, you cannot use this user name. For now, Platfor follows the Instagram user name policy.</Typography>
            :
            message === 'ALREADY_EXSIST' 
            ?
              <Typography>This user name already exists</Typography>
            :
            message === 'NAME_DONE' 
            ?
              <Typography>Done!</Typography>
            :
              null
          }
          {
              user.completed?
                  <div>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}>
                    <TextField
                      InputLabelProps={{
                                  style: { color: 'grey' },
                                }}
                      label="Description"
                      multiline
                      defaultValue={user.description}
                      variant="outlined"
                      onChange={e=>setDescription(e.target.value)}
                    />
                    <Button style={{marginLeft: '45px'}}onClick={handleDescriptionSubmit}>Edit</Button>
                  </div>
                  {/*
	                  <div style={{paddingLeft: '68px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}>
	                      <CompactPicker
	                          colors={['#FFEBEE', '#F3E5F5', '#E8EAF6', '#E3F2FD', '#E0F7FA', '#E0F2F1', '#E8F5E9', '#F1F8E9', '#F9FBE7', '#FFFDE7', '#FFF8E1', '#FFF3E0', '#EFEBE9', '#FAFAFA', '#ECEFF1', '#FFFFFF`']}
	                          color={chosenColor}
	                          onChange={color=>setChosenColor(color)}
	                          onChangeComplete={color=>setChosenColor(color)}
	                      />
	                      <Button onClick={handleColorSubmit}>Edit</Button>
	                  </div>
	                  <Typography>Theme color</Typography>
                  */}
                  {
                    loading
                    ?
                      <CircularProgress />
                    :
                      null
                  }
                  {
                    message === 'DONE' 
                    ?
                      <Typography>Done!</Typography>
                    :
                      null
                  }
                  </div>
                :
                  null
        }
        {/* <Button style={{marginTop: '50px'}} variant='contained' onClick={handleSignOut}>sign out</Button> */}
        {
        	/*
        	!extensionInstalled
        	?
        		<div style={{paddingTop: '30px'}}>
	        		<ExtensionButton />
        		</div>
        	:
        		null
        	*/
        }
        {/*<Button onClick={handleDeleteAccount}>DELETE YOUR ACCOUNT</Button>*/}
      </Root>
  );
}
