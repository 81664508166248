
/* global chrome */
import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InboxIcon from '@mui/icons-material/Inbox';

import { Paper, InputBase, Divider, IconButton, Popper, Typography, Button, List, ListItem, ListItemText, ListItemIcon, TextField, CircularProgress, Avatar } from '@mui/material'
import Skeleton from '@mui/material/Skeleton';

import ClickAwayListener from '@mui/material/ClickAwayListener';


const WAIT_INTERVAL = 300;
const ENTER_KEY = 13;

const SearchBar = props => {
	const user = useSelector(state => state.user);
	const api = useSelector(state => state.api);
	const dispatch = useDispatch();

  const [input, setInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cursor, setCursor] = useState(-1);

  const [mouseOver, setMouseOver] = useState(false);

  const navigate = useNavigate();

	const handleChange = event => {
		setInput(event.target.value);
		setLoading(true);
		setAnchorEl(event.currentTarget);
    //debounce
		//clearTimeout(this.timer);
		
		const anchorEl = event.currentTarget;
		const value = event.target.value;

		if(!event.target.value.length > 0) {
			setAnchorEl(null);
		}

		if(event.target.value.length > 0) {
			triggerChange(value, anchorEl)
			//timer = setTimeout(() => triggerChange(value, anchorEl), WAIT_INTERVAL);
		}
	}

	const triggerChange = (value, anchorEl) => {
		fetch(api.root + `/api/search/options?text=${value}`, { credentials: 'include' })
			.then(res => res.json())
			.then(json => {
				console.log('json is', json)
				const { options } = json;
				console.log('setting options')
				setLoading(false);
				setOptions(options);
				//if(!this.state.anchorEl) this.setState({anchorEl});
			})
			.catch(err => console.log(err))
	}

	const handleOnFocus = event => {
		if(input.length > 0) setAnchorEl(event.currentTarget);
	}


	const handleKeyDown = e => { 
		if(e.keyCode === ENTER_KEY){
      if(cursor>-1){
        console.log('current state are', this.state);
        setCursor(-1)
        const option = options[cursor];
        switch(option.type){
        	case "PERSON":
        		handlePersonOptionClick(option);
        		break;
        	case "KEYWORD":
        		handleKeywordOptionClick(option);
        		break;
        }

        //this.handleSearch(this.state.options[this.state.cursor].name);
      }
      else{
      	console.log('calling the handleSearch')
        this.handleSearch(this.state.input);
      }
		}

		if(e.keyCode === 27){
      setAnchorEl(null);
      setCursor(-1);
		}

    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > -1) {
    	setCursor(cursor -1)
	    e.preventDefault();
    } else if (e.keyCode === 40 && cursor < this.state.options.length - 1) {
    	setCursor(cursor + 1);
    e.preventDefault();
    }
	}

	const handleSearch = text => { 
    //if(name.charAt(0) !== '#') option.name = '#' + option.name;
		dispatch({type: 'SET_SEARCH', path: '/api/search/init' + '/?text=' + encodeURIComponent(text), method: 'get', currentText: text});
		navigate('/search')
		setAnchorEl(null);
	}

	const handlePersonOptionClick = option => {
		navigate(`/${option.username}`);	
		setAnchorEl(null);
    //chrome.runtime.sendMessage(this.extensionId, {type: 'OPEN_ACTIVITY_BY_WEBPAGE', personName: option.username, user: this.user }, function(response) {})
	}

	const handleKeywordOptionClick = option => {
		handleSearch(option.name);
	}

	const handleClickAway = value => {
		console.log('handleClickAway is functioning')
		setAnchorEl(null);
	}

	const open = Boolean(anchorEl);
	const id = open ? 'no-transition-popper' : undefined;

		return (
			<div>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div>
						<div>
              <TextField
                label="Search"
                variant="outlined" 
                size="small"
                placeHolder='search'
                autoComplete='off'
								onChange={handleChange}
								onFocus={handleOnFocus}
								value={input}
								onKeyDown={handleKeyDown}
              />
						</div>
						<Popper id={id} open={open} anchorEl={anchorEl}>
							<Paper>
								<List component="nav" aria-label="main mailbox folders">
	                {
	                    loading
	                    ?
	                      <ListItem><Skeleton width='60px'/></ListItem>
	                    :
											options.length > 0 
											?
												options.map((option, i) => 
												{
													switch(option.type){
														case "PERSON":
															return(
																<ListItem 
																	key={i}
				                          className={cursor === i ? 'active' : null}
				                          style={{backgroundColor: cursor === i ? 'F3F3F3' : null}}
				                          button 
				                          onClick={() =>{handlePersonOptionClick(option); setAnchorEl(null);}}
				                        >
				                        	<ListItemIcon>
					                        	<Avatar src={option.profileImageUrl}/>
				                        	</ListItemIcon>
				                          <ListItemText 
				                          	primary={option.username}
				                          />
																</ListItem>
															)
															break;
														case "KEYWORD":
														 return(
																<ListItem 
																	key={i}
				                          className={cursor === i ? 'active' : null}
				                          style={{backgroundColor: cursor === i ? 'F3F3F3' : null}}
				                          button 
				                          onClick={() =>handleKeywordOptionClick(option)}
				                        >
				                          <ListItemText 
				                          	primary={option.name}
				                          	secondary={option.contentCount}
				                          />
																</ListItem>
														 )
														 break;
													}
												})
											:
												null
											}
								</List>
							</Paper>
						</Popper>
				</div>

				</ClickAwayListener>
			</div>
		)
  }

export default SearchBar; 
