  import produce from 'immer';

export default (state = { contents: [], hasMore: true, category: '/All', pageNum: 0, loadedTime: null }, action) => produce(state, draftState => {
	switch (action.type){
		case 'SET_HOT_$':
			const contents  = action.data;
			const { category } = action;
			const loadedTime = new Date().toUTCString();
      const hasMore = contents.length === 8? true: false; 
			draftState = { ...draftState, contents, category, loadedTime, pageNum: 1 };
			break;

		case 'MORE_HOT_$':
      draftState.contents.concat(action.data);
      draftState.pageNum ++;
			if(action.data.length === 8){
        draftState.hasMore = true;
			} else {
				draftState.hasMore = false;
			}
			break;
	}
	return draftState;
})

