import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Line, Circle, Group } from 'react-konva';
import { Html } from 'react-konva-utils';

const BezierCurve = () => {
  const [hovered, setHovered] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState({ x: 150, y: 150 }); // Initial position for the anchor

  const lineRef = useRef(null);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    console.log('mouseLeave')
    setHovered(false);
  };

  const handleMouseMove = e => {
    // Update the position of the anchor point to the cursor position
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    console.log(calculatePosition(e))
    setAnchorPosition({
      x: e.evt.pageX,
      y: e.evt.pageY,
    });
  };

  const calculatePosition = e => {
    const stage = e.target.getStage();
    const stageScale = stage.scaleX();
    const stagePosition = stage.position();
    const pointerPosition = stage.getPointerPosition();
    
    // Calculate the position relative to the stage (considering scale and position)
    const relativePosition = {
      x: (pointerPosition.x - stagePosition.x) / stageScale,
      y: (pointerPosition.y - stagePosition.y) / stageScale
    };
    return relativePosition;
  }

  const handleDragEnd = e => {
    console.log('e.target.x()', e.target.x())
  }


  return (
      <>
        <Line
          points={[600, 600, 400, 400, 500, 500, 400, 400]}
          tension={0.5}
          stroke="black"
          ref={lineRef}
          strokeWidth={2}
          hitStrokeWidth={10}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          listening={true}
          draggable
          onDragEnd={handleDragEnd}
        />
        {hovered && (
          <Circle
            x={anchorPosition.x}
            y={anchorPosition.y}
            radius={10}
            fill="red"
            draggable
            onDragMove={(event) => {
            }}
          />
        )}
      </>
    
  );
};

export default BezierCurve;