/* global chrome */

import React, { Component, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import Reply from './reply';
import CommentEditForm from './forms/commentEditForm';
import ReplyForm from './forms/replyForm';
import CommentOwnerMenu from './menus/commentOwnerMenu';
import CommentReportMenu from './menus/commentReportMenu';

import { ListItem, Divider, ListItemAvatar, Avatar, ListItemText, Typography, IconButton, ListItemSecondaryAction, Button, Chip, Tooltip } from '@mui/material'
import AvatarGroup from '@mui/material/AvatarGroup';
import Skeleton from '@mui/material/Skeleton';

import PublicIcon from '@mui/icons-material/Public';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';
import { useImmer } from 'use-immer';

import { useNavigate } from 'react-router-dom';

import moment from 'moment';

const Comment = props => {
	
    const { updateContent, comment } = props;	

    const user = useSelector(state => state.user);
    const { CLIENT_ROOT, appType } = useSelector(state => state.api);
    const newsfeed = useSelector(state => state.newsfeed);
    const dispatch = useDispatch();


    const [isCommentDeleted, setIsCommentDeleted] = useState(false);
    const [isEditFormClicked, setIsEditFormClicked] = useState(false);
    const [isReplyFormClicked, setIsReplyFormClicked] = useState(false);
    const [isMouseEntered, setIsMouseEntered] = useState(false);
    const [isChildEntered, setIsChildEntered] = useState(false);

    const [isReplyOpen, setIsReplyOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const [mouseOver, setMouseOver] = useState(false);

    const [pageNum, setPageNum] = useState(0);

    const { root } = useSelector(state => state.api);

    var isCommentOwner = false;
    if(user){
        for(var i = 0; i < comment.writer.length; i++) {
          if(comment.writer[i]._id == user._id){
            isCommentOwner = true;
        /*
        var owner = comment.writer[i];
        comment.writer.splice(i, 1);
        comment.writer.splice(0, 0, owner);
        break;
        */
    }


}
}

//const navigate = appType == 'WEB'? useNavigate() : () => {};

const handleTextClick = () => {
    console.log('props. are', props)
    if(appType == 'WEB') return;
    props.setFirstCommentShown(true)
    chrome.runtime.sendMessage({type: 'COMMENT_CLICKED'})
    chrome.tabs.getCurrent(tab => {
        chrome.tabs.sendMessage(tab.id, {type: "MOVE_DRAGGABLE", comment}, function(response) {
        })
    })
    updateContent(draft => {
        draft.comments = draft.comments.filter(c => c._id !== comment._id);
        draft.comments = [ comment, ...draft.comments ];
    })
    document.getElementById('socialbrowse-comments').scrollTop = 0;
}


const handleInitReplies = () => {
  setIsReplyOpen(true)
		if(comment.replies && comment.replies.length > 0) return; //just open the already loaded replies
		setLoading(true)
		fetch(`${root}/api/reply/init/${comment._id}/${props.loadedTime}`, { method: 'get', credentials: 'include' })
     .then(res => res.json())
     .then(json => {
        setLoading(false);
        console.log('json is', json);
        if(json.length < 8){setHasMore(false)}
            updateContent(draft =>{
               draft.comments.find(element => element._id === comment._id).replies = json;
           })
        setPageNum(pageNum+1)
    })
     .catch(err => console.log(err))
 }

 const handleMouseMove = () => {
    if(mouseOver) return;
    setMouseOver(true);
}

const handleMoreReplies = () => {
    setLoading(true);
    fetch(`${root}/api/reply/more/${comment._id}/${pageNum}/${props.loadedTime}`, { method: 'get', credentials: 'include' })
      .then(res => res.json())
      .then(json => {
        setLoading(false);
        console.log('json is', json);
        if(json.length < 8){setHasMore(false)}
            updateContent(draft =>{
                const a =	draft.comments.find(function(element){return element._id === comment._id});
                a.replies = a.replies.concat(json);
          })
        setPageNum(pageNum+1);
    })
      .catch(err => console.log(err))
}

const handleCommentLike = () => {
    //if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
    if(isCommentOwner) return;
    if(comment.userReaction.length === 0){
     fetch(`${root}/api/reaction/comment/${comment._id}/1/0`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
        const a = draft.comments.find(function(element){return element._id === comment._id})
        a.likeCount++; 
        a.userReaction = [{type: 1}];
    });
     return;
 } else {
  switch (comment.userReaction[0].type){ 
     case -1:
     fetch(`${root}/api/reaction/comment/${comment._id}/1/-1`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
       const a = draft.comments.find(function(element){return element._id === comment._id})
       a.likeCount++; 
       a.dislikeCount--;
       a.userReaction[0].type = 1;
   });
     break;
     case 0:
     fetch(`${root}/api/reaction/comment/${comment._id}/1/0`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
       const a = draft.comments.find(function(element){return element._id === comment._id})
       a.likeCount++; 
       a.userReaction[0].type = 1;
   });
     break;
     case 1:
     fetch(`${root}/api/reaction/comment/${comment._id}/1/1`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
       const a = draft.comments.find(function(element){return element._id === comment._id})
       a.likeCount--; 
       a.userReaction[0].type = 0;
   });
     break;
 }
}}

const handleCommentDislike = () => {
    if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
    //if(isCommentOwner) return;
    if(comment.userReaction.length === 0){
     fetch(`${root}/api/reaction/comment/${comment._id}/-1/0`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
        const a = draft.comments.find(function(element){return element._id === comment._id})
        a.dislikeCount++;
        a.userReaction = [{ type: -1 }];
    });
     return;
 } else {
  switch (comment.userReaction[0].type){ 
     case -1:
     fetch(`${root}/api/reaction/comment/${comment._id}/-1/-1`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
       const a = draft.comments.find(function(element){return element._id === comment._id})
       a.dislikeCount--;
       a.userReaction[0].type = 0;
   });
     break;
     case 0:
     fetch(`${root}/api/reaction/comment/${comment._id}/-1/0`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
       const a = draft.comments.find(function(element){return element._id === comment._id})
       a.dislikeCount++;
       a.userReaction[0].type = -1;
   });
    break;
     case 1:
     fetch(`${root}/api/reaction/comment/${comment._id}/-1/1`, { method: 'put', credentials: 'include' })
     .then(res => console.log('reaction update success'))
     .catch(err => console.log(err))
     updateContent(draft => {
       const a = draft.comments.find(function(element){return element._id === comment._id})
       a.likeCount--; 
       a.dislikeCount++;
       a.userReaction[0].type = -1;
   });
     break;
 }
}}

                  //<button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={handleSignOut}>Sign out</button>

const handleReplyFormClick = () => {
    if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
    if(appType == 'EXTENSION'){
        props.setFirstCommentShown(true);
        chrome.tabs.getCurrent(tab => {
            chrome.tabs.sendMessage(tab.id, {type: "MOVE_DRAGGABLE", comment}, function(response) {
            })
        })
    }
    setIsReplyFormClicked(prev => !prev)
}

const handleHashtagClick = hashtag => {
    if(appType == 'WEB'){
        //navigate(`/${comment.writer[0].username}/${hashtag.name}`)
    } else {
        //extension;
        window.open(CLIENT_ROOT + `/${comment.writer[0].username}/${hashtag.name}`, '_blank');
    }
}

/*
const setOpacity = (index, comment) => {
    if(index>0) return 0.5
    if(!props.firstCommentShown) return 0.5;
    return 1;
}
*/
  const EllipsisText = props => {
    const { children } = props

    return (
      <div style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 100,
      }}>
        {children}
      </div>
    )
  }


                return (
                    <div>
                    {
                        !isEditFormClicked 
                        ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            borderRadius: '10px',
                            marginTop: '8px',
                        }} onMouseOver={()=>setMouseOver(true)} onMouseOut={()=>setMouseOver(false)} onMouseMove={handleMouseMove}>
                        <div>
                        <IconButton
                            onClick={()=> {
                                /*
                                    chrome.runtime.sendMessage({type: "OPEN_ACTIVITY", personName: comment.writer[0].username }, function(response) {
                                    })
                                */
                                //chrome.runtime.sendMessage({type: 'UPDATE_ACTIVITY_WINDOW', url: CLIENT_ROOT + `/${comment.writer[0].username}`, windowId: 'socialbrowse-activity'})
                                window.open(CLIENT_ROOT + `/${comment.writer[0].username}`, '_blank');
                            }}
                        >
                          <Avatar alt="" src={comment.writer[0].profileImageUrl}/>
                        {
                            /*
                        <AvatarGroup max={1}>
                        {
                          comment.writer.length === 2
                          ?
                          <div>
                          <Avatar alt="" src={comment.writer[0].profileImageUrl}/>
                          <Avatar alt="" src=''>
                          +1
                          </Avatar>
                          </div>
                          :
                          comment.writer.map(person => <Avatar alt="" src={person.profileImageUrl}/>)
                        }
                      </AvatarGroup>
                      */
                      }
                      </IconButton>
                      </div>
                        <div style={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                        }}>
                      <div style={{display:'flex', cursor: 'pointer', flexDirection: 'row'}} onClick={handleTextClick}>
                       <Typography gutterBottom>{comment.writer[0].username || comment.writer[0].email + "  " + comment.createdAt.substr(0, 10)}</Typography>
                       <Typography style={{paddingLeft: '15px', paddingRight: '15px', cursor: 'pointer'}} onClick={handleTextClick}  gutterBottom>{moment(comment.createdAt).fromNow()}</Typography>
                        {
                            comment.isPublic
                            ?
                                <PublicIcon fontSize='small'/>
                            :
                                <PeopleAltIcon fontSize='small'/>
                        }
                    </div>
                    <Typography style={{wordBreak: 'break-all', cursor: 'pointer'}} onClick={handleTextClick}>
                    <span align='left' style={{marginRight: comment.hashtags.length>0||comment.persontags.length>0? '10px': '0px'}}>
                        {comment.hashtags.map(hashtag => {
                            return(
                                <Chip
                                    color='primary'
                                    style={{
                                      backgroundColor: hashtag.color,
                                    }}
                                    label={<EllipsisText>{'#' + hashtag.name}</EllipsisText>}
                                    onClick={e => handleHashtagClick(hashtag)}
                                />
                            )
                        })}
                        {comment.persontags.map(persontag => <Chip label={'@'+persontag.username}/>)}
                    </span>
                        {comment.text}
                    </Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}>
                        {comment.likeCount<=0? 0 : comment.likeCount}
                    <IconButton
                        IconStyle={{width: 60, height: 60}}
                        aria-label="thumb-up"
                        onClick={handleCommentLike}
                        size="small"
                    >
                    {
                        comment.userReaction && comment.userReaction.length > 0 && comment.userReaction[0].type === 1 
                        ? 
                            <FavoriteIcon fontSize='small' style={{color: 'red'}}/>
                        :
                            <FavoriteBorderIcon fontSize='small'/>
                    }
                    </IconButton>
                    {//comment.dislikeCount<=0? 0: comment.dislikeCount
                    }
                    <Button onClick={handleReplyFormClick} size="small">
                        Reply      
                    </Button>
                    { 
                        isCommentOwner 
                        ?
                            <CommentOwnerMenu comment={comment} updateContent={updateContent} setIsEditFormClicked={setIsEditFormClicked} setMouseOver={setMouseOver}/> 
                        : 
                                                //<CommentReportMenu commentId={comment._id} updateContent={updateContent} handleOpen={props.handleOpen} setMouseOver={setMouseOver}/> 
                            null
                        }
                        </div>

                        </div>
            {/*
                                <div className={{
                                    width: '100%',
                                    //width: '425px',
                                    //display: 'flex',
                                    justifyContent: 'flex-start',
                                }} style={{opacity: mouseOver? 1 : 0}}>
                                    { 
                                        isCommentOwner 
                                        ?
                                            <CommentOwnerMenu contentId={comment.contentId} commentId={comment._id} updateContent={updateContent} setIsEditFormClicked={setIsEditFormClicked} setMouseOver={setMouseOver}/> 
                                        : 
                                            <CommentReportMenu commentId={comment._id} updateContent={updateContent} handleOpen={props.handleOpen} setMouseOver={setMouseOver}/> 
                                    }
                                </div>
                            */}
                            </div>
                            :
                            <CommentEditForm comment={comment} updateContent={updateContent} setIsEditFormClicked={setIsEditFormClicked}/>
                        }
                        <div style={{paddingLeft: '50px'}}>
                        { 
                            isReplyFormClicked 
                            ? 
                            <ReplyForm comment={comment} updateContent={updateContent} setIsReplyFormClicked={setIsReplyFormClicked} setIsReplyOpen={setIsReplyOpen}/> 
                            : 
                            null 
                        }
                        {
                            /*
                            !loading && comment.replyCount > 0 && isReplyOpen === true
                            ?
                                <Button onClick={() => setIsReplyOpen(false)} size="small">Hide</Button>    
                            :
                                null
                            */ 
                        }
                        {
                            /*
                            comment.replyCount > 0 && isReplyOpen === false
                            ?
                                <Button className={classes.showReplies}  onClick={handleInitReplies} size="small">
                                    {`Show ${comment.replyCount} replies`}
                                </Button>
                            :
                            loading
                            ?
                                <Skeleton/>
                            :
                                null
                            */
                        }
                        {
                            comment.replies && comment.replies.length > 0 
                            ?
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                            {
                                comment.replies.map(reply =>(	
                                    <Reply reply={reply} updateContent={updateContent} setIsChildEntered={setIsChildEntered} contentId={comment.contentId} commentId={comment._id} setIsReplyOpen={setIsReplyOpen} handleOpen={props.handleOpen} />
                                    ))
                            }
                            { 
                                /*
                                loading
                                ?
                                <Skeleton/>
                                :
                                    comment.replies.length >= 8 && hasMore === true 
                                ? 
                                    <Button onClick={handleMoreReplies} size="small">More</Button> 
                                : 
                                null  
                                */
                            }
                            </div>
                            :
                            null
                        }

                        </div>
                        </div>
                        );
}

export default Comment;

