import produce from 'immer';

export default (state = { loading: false, contents: [], hasMore: false, pageNum: 0, currentText: null }, action) => produce(state, draftState => {
	switch (action.type){
		case 'SET_SEARCH':
			draftState.contents = [];
			draftState.loading = true;
			break;

		case 'SET_SEARCH_$':
			const loading = false;
      let hasMore;
      if(action.data.length == 0){
        hasMore = false;
      } else {
        hasMore = true;
      }
			const { contents }  = action.data;

			const { currentText } = action;
			const loadedTime = new Date().toUTCString();
			const pageNum = 1;
			draftState = { ...draftState, loading, contents, currentText, pageNum, loadedTime };
			break;

		case 'MORE_SEARCH_$':
			if(action.data.contents.length > 0){
				draftState.contents.concat(action.data.contents);
			} else {
				draftState.hasMore = false;
			}
			draftState.pageNum++;
			break;
	}
	return draftState;
})

