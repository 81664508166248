import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ErrorIcon from '@mui/icons-material/Error';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector, useDispatch } from 'react-redux';

export default function CommentOwnerMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
	const root = useSelector(state => state.api).root;
	const { commentId, replyId, updateContent, setIsEditFormClicked } = props;
	const dispatch = useDispatch();

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
    props.setMouseOver(false);
  }

 	const handleReplyDelete = () => {
    setLoading(true);
		fetch(`${root}/api/reply/${replyId}`, { method: 'delete',credentials: 'include' })
			.then(res => {
        console.log('reply delete succeedd')
        setLoading(false);
        updateContent(draft => {
          const a = draft.comments.find(element => element._id === commentId);
          a.replies = a.replies.filter(reply => reply._id !== replyId);
          a.replyCount--;
        })
        handleClose();
      })
			.catch(err => console.log(err))
	}

  return (
    <div>
			<IconButton
        edge="end"
        aria-controls='simple-menu'
        aria-label="aria-haspopup"
        onClick={handleMenuClick}
        size="small"
      >
				<MoreVertIcon fontSize="small"/>
			</IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setIsEditFormClicked(true)}>Edit</MenuItem>
				<MenuItem onClick={handleReplyDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
