import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';

import {AppBar, Toolbar, IconButton} from '@mui/material'

import SecurityIcon from '@mui/icons-material/Security';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';


const Extension = props => {

  const navigate = useNavigate();
  const location = useLocation();
  const { extensionInstalled } = useSelector(state => state.api);
  const isDesktop= useMediaQuery({
    query: '(min-width: 1224px)'
  })


  const openExtensionPage= () => {
    var url = 'https://chrome.google.com/webstore/detail/trends/malnhaodjdegcljjebmpopbjmckblndn';
    window.open(url,'_blank');
  }

  const openPrivacy= () => {
    navigate('/privacy')
  }

  const openInstagram= () => {
    var url = 'https://instagram.com/platforworld';
    window.open(url);
  }

  const openMail= () => {
    var url = 'mailto:memeek01@gmail.com';
    window.open(url);
  }
	return (
      <>
    {
      !extensionInstalled
      ?
        <AppBar position='fixed' style={{top: 'auto', bottom: 0, backgroundColor: 'fafafa', flexGrow: 1, alignItems: 'center'}}>
          <Toolbar>
            {
              isDesktop
              ?
                <div align='center'>
                  <a
                    className="btn btn-custom btn-lg page-scroll"
                    onClick={openExtensionPage}
                    style={{cursor: 'pointer'}}
                  >
                    <img src="img/chrome.svg" style={{width: '30px', height: '30px', paddingRight: '10px'}} alt="chrome extension logo" />
                      {location.pathname=='/kr'? 'Socialbrowse를 설치해 채팅 기능을 추가하세요': 'Install SocialBrowse to add the chat feature'}
                  </a>
                </div>
              :
                <div align='center'>
                  <a
                    className="btn btn-custom btn-lg page-scroll"
                    onClick={openExtensionPage}
                    style={{cursor: 'pointer'}}
                  >
                    <img src="img/chrome.svg" style={{width: '30px', height: '30px', paddingRight: '10px'}} alt="chrome extension logo" />
                    {location.pathname=='/kr'? '현재 PC 크롬에서 이용 가능하세요!': 'Now available on PC Chrome!'}
                  </a>
                </div>
            }
          </Toolbar>
        </AppBar>
      :
        null
    }
  </>
    );
}

export default Extension;
