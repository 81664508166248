import { put, call, select, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { SERVER_ROOT } from '../env';

function* callApi(action) {
	console.log('action is', action)
	const { path, method, body } = action;
	
	console.log('calling api' , SERVER_ROOT + path)
	const json = yield fetch(SERVER_ROOT + path, { credentials: 'include', method, body })
		.then(response => {
			if(!response.ok) throw Error(response.statusText);
			console.log('calling api was successful', response)
			return response.json();    
		})
		.catch(err => console.log('calling api failed', err));    

	console.log('json is ', json)
	action.type = `${action.type}_$`;
	action.data = json;
	yield put(action);
}


export default function* rootSaga() {
  yield all([

		//user
		//yield takeEvery('SET_USER', callApi),

		yield takeEvery('SET_HOT', callApi),
		yield takeEvery('MORE_HOT', callApi),

    yield takeEvery('SET_SEARCH', callApi),
    yield takeEvery('MORE_SEARCH', callApi), 

		yield takeEvery('ADD_NEWSFEED_ENTITY', callApi),
		yield takeEvery('DELETE_NEWSFEED_ENTITY', callApi),


		yield takeEvery('OPEN_DRAWER', callApi),
		yield takeEvery('ACTIVITY_BY_TAG', callApi),
		yield takeEvery('DELETE_USER_TAG', callApi),
		yield takeEvery('ADD_USER_TAG', callApi),

		yield takeEvery('FOLLOW_PERSON', callApi),
		yield takeEvery('UNFOLLOW_PERSON', callApi),

    yield takeEvery('SET_USER', 
      function* (action) {
        console.log('calling api' , SERVER_ROOT + '/auth/user')
        const json = yield fetch(SERVER_ROOT + '/auth/user', { credentials: 'include', method: 'GET' })
          .then(response => {
            console.log('calling api was successful', response)
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.data = json;
        yield put(action);
      }
    ),

		yield takeEvery('CHANGE_USER_DESCRIPTION', 
      function* (action) {
        console.log('calling api' , SERVER_ROOT + '/auth/user/name')
        const json = yield fetch(SERVER_ROOT + '/auth/user/name', { credentials: 'include', method: 'POST', body: { text: action.text } })
          .then(response => {
            console.log('calling api was successful', response)
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.data = json;
        yield put(action);
      }
    ),

		yield takeEvery('CHANGE_USER_NAME',
      function* (action) {
        console.log('calling api' , SERVER_ROOT + '/auth/user/name')
        const json = yield fetch(SERVER_ROOT + '/auth/user/description', { credentials: 'include', method: 'POST', body: { text: action.text} })
          .then(response => {
            console.log('calling api was successful', response)
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

		yield takeEvery('OPEN_NOTIFICATION_CONTENT',
      function* (action) {
        const { notification } = action;
        console.log('notification is', notification)
        console.log('calling api' , SERVER_ROOT + '/auth/notification/load')
        const json = yield fetch(SERVER_ROOT + '/auth/notification/load', { credentials: 'include', method: 'POST', body: JSON.stringify({notification}), headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            console.log('calling api was successful', response)
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

		yield takeEvery('CHANGE_USER_DISCLOSURE',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/disclosure`, { credentials: 'include', method: 'PUT', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

    yield takeEvery('CHANGE_COMMENT_DISCLOSURE',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/comment/disclosure`, { credentials: 'include', method: 'PUT', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

    yield takeEvery('REQUEST_FRIEND',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/relation?type=REQUEST_FRIEND&person_id=${action.personId}`, { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

    yield takeEvery('ACCEPT_FRIEND',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/relation?type=ACCEPT_FRIEND&person_id=${action.personId}`, { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

    yield takeEvery('DELETE_FRIEND',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/relation?type=DELETE_FRIEND&person_id=${action.personId}`, { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),

    yield takeEvery('ADD_WANTED_KEYWORD',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/keyword/wanted?encodedName=${encodeURIComponent(action.name)}`, { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield all([
          yield put({type: 'SET_USER_$', data: {user: json.user}}),
          yield put({type: 'SET_SUGGEST'})
        ])
      }
    ),

    yield takeEvery('DELETE_WANTED_KEYWORD',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/keyword/wanted?encodedName=${encodeURIComponent(action.keyword.name)}`, { credentials: 'include', method: 'DELETE', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield all([
          yield put({type: 'SET_USER_$', data: {user: json.user}}),
          yield put({type: 'SET_SUGGEST'})
        ])
      }
    ),

    yield takeEvery('ADD_UNWANTED_KEYWORD',
      function* (action) {
        const json = yield fetch(SERVER_ROOT + `/auth/keyword/unwanted?encodedName=${encodeURIComponent(action.name)}`, { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield all([
          yield put({type: 'SET_USER_$', data: {user: json.user}}),
          yield put({type: 'SET_SUGGEST'})
        ])
      }
    ),

    yield takeEvery('DELETE_UNWANTED_KEYWORD',
      function* (action) {
        console.log('action is', action)
        const json = yield fetch(SERVER_ROOT + `/auth/keyword/unwanted?encodedName=${encodeURIComponent(action.name)}`, { credentials: 'include', method: 'DELETE', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield all([
          yield put({type: 'SET_USER_$', data: {user: json.user}}),
          yield put({type: 'SET_SUGGEST'})
        ])
      }
    ),

    yield takeEvery('CHANGE_ACTION_COUNT',
      function* (action) {
        console.log('action is', action)
        const json = yield fetch(SERVER_ROOT + `/auth/actionCount?count=${action.count}`, { credentials: 'include', method: 'POST', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield all([
          yield put({type: 'SET_USER_$', data: {user: json.user}}),
          yield put({type: 'SET_SUGGEST'})
        ])
      }
    ),

    yield takeEvery('PERSON_AVATAR_CLICK',
      function* (action){
        const { history, person } = action;
        history.push(`/${person.username}`);
      }
    ), 

    yield takeEvery('SET_SUGGEST',
      function* (action){
        const json = yield fetch(SERVER_ROOT + `/api/suggest/init`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),     

    yield takeEvery('MORE_SUGGEST',
      function* (action){
        const { pageNum, loadedTime } = yield select(state => state.suggest);
        const json = yield fetch(SERVER_ROOT + `/api/suggest/more?pageNum=${pageNum}&loadedTime=${loadedTime}`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),     

    yield takeEvery('SET_ACTIVITY',
      function* (action){
        const json = yield fetch(SERVER_ROOT + `/api/activity/init?personName=${action.personName}&keywordName=${action.keywordName}`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),     

    yield takeEvery('MORE_ACTIVITY',
      function* (action){
        const { person, keywordName, pageNum, loadedTime} = yield select(state => state.activity);
        const json = yield fetch(SERVER_ROOT + `/api/activity/more?personName=${person[0].username}&keywordName=${keywordName}&pageNum=${pageNum}&loadedTime=${loadedTime}`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),   

    yield takeEvery('SET_NEWSFEED',
      function* (action){
        const json = yield fetch(SERVER_ROOT + `/api/newsfeed/init`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),     

    yield takeEvery('MORE_NEWSFEED',
      function* (action){
        const { pageNum, loadedTime} = yield select(state => state.newsfeed);
        const json = yield fetch(SERVER_ROOT + `/api/newsfeed/more?pageNum=${pageNum}&loadedTime=${loadedTime}`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} })
          .then(response => {
            return response.json();    
          })
          .catch(err => console.log('calling api failed', err));    
        console.log('json is ', json)
        action.type = `${action.type}_$`;
        action.json = json;
        yield put(action);
      }
    ),   


      
  ]);
}



