import produce from 'immer';

const reducer = (state = { loading: false, entityStrings: [], activities: [], hasMore: false, loadedTime: null }, action) => produce(state, draftState => {
	const alignCommentActivity = activity =>{
		if(activity.type !== 'COMMENT') return activity;
		const commentId = activity.action._id
		const content = activity.content[0];
		const commonComment = content.comments.find(comment => comment._id === commentId);
		if(!commonComment) return activity;
	    const newComments = [
	    	commonComment,
          	...content.comments.filter(item => item._id !== commonComment._id),
        ];
        content.comments = newComments;
		return activity;
	}
	switch (action.type){
		case 'SET_NEWSFEED': {
			draftState.loading = true;
			break;
		}

		case 'SET_NEWSFEED_$':{
	      	let { activities } = action.json;
			const loadedTime = new Date().toUTCString();
			const pageNum = 1;
			const hasMore = activities.length>0? true : false;
	        activities = activities.map(activity => {
      			return alignCommentActivity(activity)
	        })
		    draftState = { ...draftState, loading: false, activities, hasMore, pageNum, loadedTime };

		    break;
		}
		case 'MORE_NEWSFEED_$':{
			let { activities } = action.json;
			const hasMore = activities.length>0? true : false;
		    activities = activities.filter(function(item, index, self){
		  	if(!item.content) return true;
				return self.findIndex(a => item.content[0]._id == a.content[0]._id ) == index;
		    })
	        activities = activities.map(activity => {
	      		return alignCommentActivity(activity)
	        })
			draftState.activities=draftState.activities.concat(activities);
			draftState.pageNum++;
			draftState.hasMore = hasMore;
			break;
		} 

		case "SET_DEMO":{
			draftState = action.demoNewsfeedJson;
		}
	}
	return draftState;
})

export default reducer;
/*
	let content_index;
	let comment_index;
	let reply_index;
	let keyword_index;

		case 'MORE_NEWSFEED_COMMENT_$':
			draftState.new.concat(action.data);
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).comments.concat(action.data);
			break;

		case 'HANDLE_NEWSFEED_COMMENT_SUBMIT_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).comments.push(action.data);
			break;

		case 'HANDLE_NEWSFEED_REPLY_SUBMIT_$': 
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			draftState.contents(content_index).comments[comment_index].replies.push(action.data);
			break;
		
		case 'HANDLE_NEWSFEED_COMMENT_EDIT_SUBMIT_$': 
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			draftState.contents(content_index).comments[comment_index].text = action.data.text;
			break;

		case 'HANDLE_NEWSFEED_REPLY_EDIT_SUBMIT_$': 
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;})
			draftState.contents(content_index).comments[comment_index].replies[reply_index].text = action.data.text;
			break;

		case 'HANDLE_NEWSFEED_COMMENT_DELETE_$': 
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments.splice(comment_index, 1);
			break;

		case 'HANDLE_NEWSFEED_REPLY_DELETE_$':
			draftState.contents(content_index).comments(comment_index).replies.splice(reply_index, 1);
			break;

		//content
		case 'HANDLE_NEWSFEED_CONTENT_LIKE_0_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).likeCount++;
			draftState.contents(content_index).userReaction = 1;
			break;
			
		case 'HANDLE_NEWSFEED_CONTENT_LIKE_1_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).likeCount--;
			draftState.contents(content_index).userReaction = 0;
			break;

		case 'HANDLE_NEWSFEED_CONTENT_LIKE_2_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).likeCount++;
			draftState.contents(content_index).dislikeCount--;
			draftState.contents(content_index).userReaction = 1;
			break;
			
		case 'HANDLE_NEWSFEED_CONTENT_DISLIKE_0_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).dislikeCount++;
			draftState.contents(content_index).userReaction = 2;
			break;

		case 'HANDLE_NEWSFEED_CONTENT_DISLIKE_1_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).likeCount--;
			draftState.contents(content_index).dislikeCount++;
			draftState.contents(content_index).userReaction = 2;
			break;

		case 'HANDLE_NEWSFEED_CONTENT_DISLIKE_2_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			draftState.contents(content_index).dislikeCount--;
			draftState.contents(content_index).userReaction = 0;
			break;

		//comment
		case 'HANDLE_NEWSFEED_COMMENT_LIKE_0_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;});
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments[comment_index].likeCount++;
			draftState.contents(content_index).comments[comment_index].userReaction = 1;
			break;

		case 'HANDLE_NEWSFEED_COMMENT_LIKE_1_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;});
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments[comment_index].likeCount--;
			draftState.contents(content_index).comments[comment_index].userReaction = 0;
			break;
	
		case 'HANDLE_NEWSFEED_COMMENT_LIKE_2_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;});
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments[comment_index].likeCount++;
			draftState.contents(content_index).comments[comment_index].dislikeCount--;
			draftState.contents(content_index).comments[comment_index].userReaction = 1;
			break;

		case 'HANDLE_NEWSFEED_COMMENT_DISLIKE_0_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;});
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments[comment_index].dislikeCount++;
			draftState.contents(content_index).comments[comment_index].userReaction = 2;
			break;

		case 'HANDLE_NEWSFEED_COMMENT_DISLIKE_1_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;});
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments[comment_index].likeCount--;
			draftState.contents(content_index).comments[comment_index].dislikeCount++;
			draftState.contents(content_index).comments[comment_index].userReaction = 2;
			break;

		case 'HANDLE_NEWSFEED_COMMENT_DISLIKE_2_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;});
			draftState.contents(content_index).comments.push(action.data);
			draftState.contents(content_index).comments[comment_index].dislikeCount--;
			draftState.contents(content_index).comments[comment_index].userReaction = 0;
			break;
			
		//reply
		case 'HANDLE_NEWSFEED_REPLY_LIKE_0_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;});
			draftState.contents(content_index).comments[comment_index].replies[reply_index].likeCount++;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].userReaction = 1;
			break;

		case 'HANDLE_NEWSFEED_REPLY_LIKE_1_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;});
			draftState.contents(content_index).comments[comment_index].replies[reply_index].likeCount--;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].userReaction = 0;
			break;
	
		case 'HANDLE_NEWSFEED_REPLY_LIKE_2_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;});
			draftState.contents(content_index).comments[comment_index].replies[reply_index].likeCount++;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].dislikeCount--;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].userReaction = 1;
			break;

		case 'HANDLE_NEWSFEED_REPLY_DISLIKE_0_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;});
			draftState.contents(content_index).comments[comment_index].replies[reply_index].dislikeCount++;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].userReaction = 2;
			break;

		case 'HANDLE_NEWSFEED_REPLY_DISLIKE_1_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;});
			draftState.contents(content_index).comments[comment_index].replies[reply_index].likeCount--;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].dislikeCount++;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].userReaction = 2;
			break;

		case 'HANDLE_NEWSFEED_REPLY_DISLIKE_2_$':
			content_index = draftState.contents.findIndex(function(element){return element._id === action.contentId;});		
			comment_index =	draftState.contents[content_index].comments.findIndex(function(element){return element._id === action.comment_id;})
			reply_index =	draftState.contents[content_index].comments[comment_index].replies.findIndex(function(element){return element._id === action.reply_id;});
			draftState.contents(content_index).comments[comment_index].replies[reply_index].dislikeCount--;
			draftState.contents(content_index).comments[comment_index].replies[reply_index].userReaction = 0;
			break;

*/
