/* global chrome */
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { Avatar, Typography, Paper, Chip, CircularProgress, CardActionArea, Skeleton } from '@mui/material';
import Masonry from "@mui/lab/Masonry";

import { red, grey } from '@mui/material/colors';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { Favorite, ChatBubble } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import SimpleContent from 'components/simpleContent';
import SignIn from 'components/home/signIn';
import Extension from 'components/extension';

import moment from 'moment';

import demoNewsfeedJson from './demoNewsfeed.json';
import demoUserJson from './demoUser.json';


const Newsfeed = (props) => {
	const { activities, hasMore, loadedTime, pageNum, loading } = useSelector(state => state.newsfeed); 
  const newsfeed = useSelector(state => state.newsfeed);
  const { extensionInstalled, extensionId, googleAdIds } = useSelector(state => state.api);
	const user = useSelector(state => state.user);
  const history = useNavigate();
	const dispatch = useDispatch();


  const EllipsisText = (props) => {
    const { children } = props

    return (
      <div style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 100,
      }}>
        {children}
      </div>
    );
  }

  const ContentChips = props => {
    const { content } = props;
    let tagArr = [];
    content.tags.forEach(tag => tagArr.push(tag.name));
    const commonTags = user.newsfeed.tags.filter(tag => tagArr.includes(tag.name));
    if(commonTags.length === 0 ) return null;
    return (
      commonTags.map(commonTag =>
        <Chip
          color='primary'
          style={{backgroundColor: commonTag.color}}
          label={<EllipsisText>{commonTag.name}</EllipsisText>}
          //onClick={() => handleClick(tag.name)}
        />
      )
    )
  }



	useEffect(() => {
      //dispatch({type: 'SET_DEMO', demoNewsfeedJson, demoUserJson})
      dispatch({ type: 'SET_NEWSFEED', method: 'get', path: '/api/newsfeed/init' });
  }, []);

  if(!user){
    return(
      <div align='center' style={{marginTop: '200px'}}>
        <SignIn/>
      </div>
    )
  }

	const handleLoadMore = () =>{
    console.log('handleLoadMore');
    if(!user) return;
		dispatch({type:'MORE_NEWSFEED', method: 'get', path: `/api/newsfeed/more`});
	}
	


  function getTextColor(hexcolor){
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 160) ? 'black' : 'white';
  }
  const contrast = getTextColor(user.themeColor.hex);


  if(newsfeed.loading){
    return(
      <div align='center' style={{marginTop: '200px'}}>
        <Skeleton width={400} height={600}/>
      </div>
    )
  }

  const GoogleAd = props => {
    const { index } = props;
    useEffect(() => {
      const adsbygoogle = window.adsbygoogle || [];
      adsbygoogle.push({id: googleAdIds[index]});
    }, [])

    return (
      <ins className="adsbygoogle"
        style={{display:'block', width: '100%'}}
        data-ad-format="fluid"
        data-ad-layout-key="-6t+ed+2i-1n-4w"
        data-ad-client="ca-pub-1567535881205892"
        data-ad-slot={googleAdIds[index]}>
      </ins>
    )
  }


  let simpleContents = activities.map((activity, index) => { 
    console.log('activity is', activity)
    const person = activity.person[0];

    let isWriter, isTagged;
    //const isReaction = Boolean(activity.); 
    const isComment = Boolean(activity.action.writerIds);

    function addQuery(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      }
      else {
        return uri + separator + key + "=" + value;
      }
    }

    let modifiedHref;
    if(isWriter){
      modifiedHref = addQuery(activity.content[0].urls[0].href, "personId", `${activity.writerIds[0]}`)
      modifiedHref = addQuery(modifiedHref, "commentId", `${activity._id}`)
    }
    if(isTagged){
      modifiedHref = addQuery(activity.content[0].urls[0].href, "personId", `${person._id}`)
      modifiedHref = addQuery(modifiedHref, "commentId", `${activity._id}`)
    }



    return(
        <React.Fragment>
          <Paper style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
            <div style={{display: 'flex', flexDirection: 'row', margin: '15px', marginBottom: '0px'}}>
            {
              /*
                <div style={{display: 'flex'}}>
                  <Avatar src={person.profileImageUrl}/>
                </div>
              */
            }
            <div style={{display: 'flex', marginLeft: '5px'}}>
              <Typography style={{color: contrast}}>@{person.username}</Typography>
            </div>
            {
              //when it is comment
              activity.type == 'COMMENT'&&activity.action.highlightData
              ?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap style={{color: contrast}}>highlighted this part</Typography>
                </span>
              :
                null
            }
            {
              //when it is comment
              activity.type == 'COMMENT'&&!activity.action.highlightData
              ?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap style={{color: contrast}}>commented on</Typography>
                </span>
              :
                null
            }
            {
              isComment&&isTagged?
                <div style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography style={{color: contrast}}>&</Typography>
                </div>
              :
                null
            }
            {
              /*
              isTagged?
                <div style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography style={{color: contrast}}>is tagged by @{activity.writer[0].username}</Typography>
                  <Avatar 
                    src={activity.writer[0].profileImageUrl}
                    style={{width: '20px', height: '20px', marginLeft: '5px'}}
                  />
                  <Typography style={{color: contrast}}>on</Typography>
                </div>
              :
                null
              */
            }
            <div style={{display: 'flex', marginLeft: '5px'}}>
              <Typography style={{color: contrast}}>on this screen</Typography>
            </div>
         

            </div>
            {/*
              <div style={{display: 'flex', marginLeft: '20px'}}>
                <Typography style={{color: contrast}}>
                  {
                    moment(activity.createdAt).fromNow()
                  }
                </Typography>
              </div>
            */
            }
          {
            /*
              isOwner?
                <Tooltip title='Hide'>
                  <IconButton onClick={handleDoneClick} >
                    <Done fontSize='small'/>
                  </IconButton>
                </Tooltip>
              :
                null
            */
          }
          {
            activity.content[0]
            ?
              <SimpleContent content={activity.content[0]} loadedTime={loadedTime} modifiedHref={modifiedHref} />
            :
               null
          }
        </Paper>
        {
          /*
          index%8 == 0&& index < 25?
            <Paper className={classes.activity} style={{marginTop: '20px'}}>
              <GoogleAd index={index%8}/>
            </Paper>
          :
            null
          */
        }
        </React.Fragment>
    )
  })

	return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center', backgroundColor: '#fafafa', marginTop: '50px'}}>
			{ 
				activities.length === 0 
				? 
          <div style={{marginTop: '200px'}}>
             <Typography>Please follow others to see their activities</Typography> 
          </div>
				: 
          <div align='center' style={{marginBottom: '50px'}}>
            <Masonry gutter='20px' columnsCount={1} spacing={2}>
              {
                simpleContents
              }
            </Masonry>
          </div>
			}
		</div>
	)
}

export default Newsfeed;
