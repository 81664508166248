import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';

import SignIn from 'components/home/signIn';

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

const Extension = props => {

  const history = useNavigate();
  const location = useLocation();
  const { extensionInstalled } = useSelector(state => state.api);

	return (
    <div align='center' style={{marginTop: '300px'}}>
      <SignIn/>
    </div>
  );
}

export default Extension;