import produce from 'immer';

export default (state = { content: null, loadedTime: null, isOpen: false, isDrawerOpen: false, zIndex: 1200 }, action) => produce(state, draftState => {
		const alignCommentActivity = activity =>{
			if(!activity.writerIds) return activity;
			//shallow copy.
			const copied = { ...activity, content: undefined }
			const content = activity.content[0];
			content.comments = content.comments.filter(comment => comment._id !== copied._id);
			content.comments = [copied].concat(content.comments);
			return activity;
		}
	switch (action.type){
		case 'OPEN_DRAWER_$':
			draftState = { ...draftState, isDrawerOpen: true, zIndex: 1200 };
			break;
		case 'CLOSE_DRAWER_$':
			draftState = { ...draftState, isDrawerOpen: false };
			break;

		case 'OPEN_DIA_$':{
			const { content, updateOriginal } = action;
	        const zIndex = draftState.isDrawerOpen? 1400 : 1200;
			draftState = { ...draftState, content, updateOriginal, loadedTime: action.loadedTime, isOpen: true, zIndex };
			break;
		}
		case 'OPEN_NOTIFICATION_CONTENT':
			draftState = { ...draftState, content:null, updateOriginal: null };
			break;
      
		case 'OPEN_NOTIFICATION_CONTENT_$': {
	      	const zIndex = draftState.isDrawerOpen? 1400 : 1200;
	      	const { content } = action.json;
	  		content.comments = content.comments.filter(function(item, index, self) {
		    	return self.findIndex(a => item._id == a._id ) == index;
			})	
			draftState = { ...draftState, content, isOpen: true };
			break;
		}

		case 'CLOSE_DIA_$':
			draftState = { ...draftState, content: null, loadedTime: null, isOpen: false, zIndex: 1200 };
			break;
	}
	return draftState;
})

