import { io } from 'socket.io-client';
import { SERVER_ROOT, CLIENT_ROOT } from './env';

// "undefined" means the URL will be computed from the `window.location` object

const socket = io(SERVER_ROOT + '/user', {
	autoConnect: false,
	reconnection: false,
	"reconnection limit": 2000,
	"max reconnection attempts": 4,
    withCredentials: true,
	cors: {
	    origin: SERVER_ROOT,
	    methods: ["GET", "POST"]
	}
});

socket.customPayload = {};

// Function to send a message
socket.checkAndEmit = function(event, message) {
    // Check if the socket is connected
    if (!socket.connected) {
      console.log("Socket is not connected. Attempting to connect...");
  
      // Connect the socket
      socket.connect();
  
      // Once connected, emit the message
      socket.once("connect", () => {
        console.log("Socket connected. Sending message...", event, message);
        socket.emit(event, message);
      });
  
      // Optionally handle connection error
      socket.once("connect_error", (err) => {
        console.error("Connection failed:", err.message);
      });
    } else {
      // If already connected, send the message directly
      console.log("Socket is already connected. Sending message...", event, message);
      socket.emit(event, message);
    }
  }

export default socket;