import React, { useEffect, useRef } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';


import crownImg from './crown.png'

const SubscriptionPricing = () => {
  const user = useSelector(state => state.user)
  const paypalRef = useRef();


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=AQLWSqAOUDOvu0plKDyRd2h1Jk_MBGrsGvicOXcDFU1yKO9qP4bGydf2ON9SzUkqaexjEb9RUc1fsd50&vault=true&intent=subscription&locale=en_US';
    script.setAttribute('data-sdk-integration-source', 'button-factory');
    script.async = true;
    script.onload = () => {
      if (window.paypal) {
        window.paypal.Buttons({
          style: {
            shape: 'rect',
            color: 'gold',
            layout: 'vertical',
            label: 'subscribe'
          },
          createSubscription: function(data, actions) {
            return actions.subscription.create({
              plan_id: 'P-0V955510374193316MZHDMUY',
              custom_id: user?._id,
            });
          },
          onApprove: function(data, actions) {
            alert(data.subscriptionID); // Optional success message for the subscriber
          }
        }).render(paypalRef.current);
      }
    };

    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div
        style={{
          marginTop: '100px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '20px',
          width: '400px',
          height: '200px',
        }}
      >
        <h2>Free Plan</h2>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>Maximum 100 pins per interactive screen</li>
          <li>5 interactive screens per user</li>
        </ul>
      </div>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '20px',
          width: '400px',
          height: '400px',
          backgroundColor: '#f5f5f5',
        }}
      >
        <h2>Donor Plan(1.99$/month)</h2>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>Maximum 300 pins per interactive screen</li>
          <li>Unlimited screens</li>
          <li>Private interactive screen available</li>
          <li>Screen management features</li>
        </ul>
        {
          user
          ?
            <div ref={paypalRef}/>
          :
            null
        }
        <img src={crownImg} style={{width: '50px'}}/>
      </div>
      <Typography>Socialbrowse is currently maintained by your donation. Please support us!</Typography>
    </div>
  );
};

export default SubscriptionPricing;
