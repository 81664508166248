	import produce from 'immer';


const reducer = (state = null, action, loaded=false) => produce(state, draftState => {
	switch (action.type){
		case 'SET_USER_$':
			console.log('set user data', action.data)
			draftState = action.data.user;
			break;

		case 'DESTROY_USER_$':
			draftState = null;
			break;

		case 'ADD_KEYWORD_$':
			draftState = action.json.user;
			break;

		case 'DELETE_KEYWORD_$':
			draftState = action.json.user;
			break;

		case 'ADD_NEWSFEED_TAG_$':
			draftState.newsfeed.tags.push(action.data);
			break;

		case 'DELETE_NEWSFEED_TAG_$':
			draftState.newsfeed.tags = draftState.newsfeed.tags.filter(tag => tag.name !== action.tag.name);
			break;

		case 'ADD_NEWSFEED_ENTITY_$':
			draftState.newsfeed.entityStrings.push(action.entityString);
			break;

		case 'DELETE_NEWSFEED_ENTITY_$':
			draftState.newsfeed.entityStrings = draftState.newsfeed.entityStrings.filter(entityString => entityString !== action.entityString);
			break;

		case 'CHANGE_USER_NAME_$':
		    draftState.username = action.text;
		    draftState.completed = true;
			break;

		case 'CHANGE_USER_DESCRIPTION_$':
		    draftState.description = action.text;
			break;

		case 'CHANGE_USER_PICTURE_$':
		    draftState.profileImageUrl = action.imgUrl;
			break;

		case 'CHANGE_USER_COLOR_$':
		    draftState.themeColor = action.color;
			break;

		case 'CHANGE_USER_DISCLOSURE':
			draftState.isPublic = !draftState.isPublic;
			break;

		case 'CHANGE_COMMENT_DISCLOSURE':
			draftState.isCommentPublic = !draftState.isCommentPublic;
			break;

		case 'OPEN_NOTIFICATION':
			draftState.notificationCount = 0;
			break;

		case 'OPEN_NEWSFEED':
			draftState.newsfeedCount = 0;
			break;

		case 'SET_DEMO':
			draftState = action.demoUserJson;
			break;

		case 'SET_DEMO_USER':
			draftState = action.demoUserJson;
			break;
	}
	return draftState;
})

export default reducer;

