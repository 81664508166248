
let SERVER_ROOT;
let CLIENT_ROOT;
let extensionId;

//extensionId = 'malnhaodjdegcljjebmpopbjmckblndn';

if (process.env.NODE_ENV === 'development') {
  SERVER_ROOT = 'http://localhost:8080';
  CLIENT_ROOT = 'http://localhost:3001';
  extensionId = 'mdiamglopikhbbahjomkafkceggdoing';
} else {
  SERVER_ROOT = 'https://api.hashely.me';
  CLIENT_ROOT = 'https://hashely.me';
  extensionId = 'malnhaodjdegcljjebmpopbjmckblndn';
}

const googleAdIds = [
  '8301814549',
  '4277442070',
  '2879765779',
  '8650171047',
  '7808696819',
]
    

const EXTENSION_URL = 'https://chrome.google.com/webstore/detail/trends/malnhaodjdegcljjebmpopbjmckblndn';

module.exports = { SERVER_ROOT, CLIENT_ROOT, extensionId, googleAdIds };
